import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Button } from '@mui/material';
import { basicAuth } from '../../Api/crmPortal';
import BuyNowModal from '../popups/buyNowModal';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';

const PaymentForm = ({ userData, packageDetails, setPageDisable }) => {
  const [openProceedTopay, setOpenProceedToPay] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loaderOpen, setLoader] = useState(false);
  // const handleClose = () => {
  //   // window.open(
  //   //   "https://api.payu.in/public/#/feab6a29ed881b0a680ef98e0971a6ba/paymentoptions",
  //   //   "_blank"
  //   // );
  //   setOpenProceedToPay(false);
  // };
  // const handleApprove = () => {
  //   window.open(
  //     "https://api.payu.in/public/#/feab6a29ed881b0a680ef98e0971a6ba/paymentoptions",
  //     "_blank"
  //   );
  //   setOpenProceedToPay(false);
  // };
  const handleSubmit = async (event, amt) => {
    setLoader(true);
    // setPageDisable(false);
    // setOpenProceedToPay(true);
    try {
      if (amt) {
        try {
          const formData = {
            amount: amt || packageDetails?.amount,
            productinfo: packageDetails?.productinfo,
            phone: packageDetails?.phone,
            firstName: packageDetails?.firstName,
            packageInstanceId: packageDetails?.packageInstanceId,
            couponCode: packageDetails?.couponCode,
            totalAmount: packageDetails?.amount,
            isPartPayment: !!amt,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_CRM_PAY_URL}/paymentgw/initpartpayment`,
            formData,
            basicAuth
          );
          if (response?.data?.url) {
            setLoader(false);
            window.location.href = response?.data?.url;
          } else if (response?.data?.message) {
            setLoader(false);
            setOpenProceedToPay(true);
            setModalMessage(response?.data?.message);
          }
        } catch (error) {
          setLoader(false);
          console.error('Error:', error);
        }
      } else {
        try {
          const formData = {
            amount: packageDetails?.amount,
            productinfo: packageDetails?.productinfo,
            phone: packageDetails?.phone,
            firstName: packageDetails?.firstName,
            packageInstanceId: packageDetails?.packageInstanceId,
            couponCode: packageDetails?.couponCode,
            totalAmount: packageDetails?.amount,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_CRM_PAY_URL}/paymentgw/initpay`,
            formData,
            basicAuth
          );
          if (response?.data) {
            window.location.href = response?.data?.url;
            // setOpenProceedToPay(true);
            setLoader(false);
          }
          window.open(response?.data?.url, '_blank');
        } catch (error) {
          setLoader(false);
          console.error('Error:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ width: '100%', opacity: loaderOpen && '0.5' }}>
      {/* <Button
        variant="contained"
        sx={{
          height: '50px',
          width: '100%',
          backgroundColor: '#003CC5',
          fontFamily: 'matterMedium',
          fontSize: '18px',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#003CC5',
            boxShadow: 'none',
          },
        }}
        onClick={(e) => handleSubmit(e)}
        disabled={!isButtondisabled}
      >
        Proceed to Pay
      </Button> */}
      <div className="city-btn-container">
        <div className="city-btn-paynow">
          <Button
            sx={{
              width: '100%',
              height: '40px',
              backgroundColor: '#003cc4',
              borderRadius: 1,
              textTransform: 'none',
              fontFamily: 'matterMedium',
            }}
            variant="contained"
            onClick={(e) => handleSubmit(e, 500)}
            disabled={loaderOpen}
          >
            Pay Rs 500 Advance
          </Button>
          <p
            className="mobile-hide"
            style={{
              paddingTop: '5px',
              fontFamily: 'matterThin',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            Balance amount to be paid post completion of your car inspection
          </p>
        </div>
        <div className="city-btn-paynow">
          <Button
            sx={{
              width: '100%',
              height: '40px',
              // fontSize: '20px',
              // backgroundColor: '#003cc4',
              borderRadius: 1,
              textTransform: 'none',
              borderStyle: 'dashed',
              fontFamily: 'matterMedium',
            }}
            variant="outlined"
            onClick={(e) => handleSubmit(e, null)}
            disabled={loaderOpen}
          >
            Pay Full Amount
          </Button>
          <p
            className="mobile-hide"
            style={{
              paddingTop: '5px',
              fontFamily: 'matterThin',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            By selecting full Payment, you agree to our{' '}
            <strong>Terms of service</strong> and{' '}
            <strong>Privacy Policy</strong>
          </p>
        </div>
      </div>
      {loaderOpen && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <BuyNowModal
        open={openProceedTopay}
        handleClose={() => setOpenProceedToPay(false)}
        loaderOpen={loaderOpen}
        handleApprove={() => setOpenProceedToPay(false)}
        message={modalMessage}
      />
    </div>
  );
};

export default PaymentForm;
