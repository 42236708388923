import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PlanDetailsBuyNow from "./PlanDetailsBuyNow";
import Compare from "../compare/compare";
import QueryComponent from "../queryComponent/queryComponent";
import BrandGrade from "../brand-grade/brandGrade";
import Price from "../price/price";
import Customers from "../customers/customers";
import Faqsection from "../../containers/faq/faqSection";

const BuyNow = () => {
  return (
    <div>
      <Header />
      <PlanDetailsBuyNow />
      <Compare />
      <QueryComponent />
      <BrandGrade />
      <Price />
      <Customers />
      <Faqsection />
      <Footer />
    </div>
  );
};

export default BuyNow;
