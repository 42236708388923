import { getRequest, postRequest } from "../httpHelpers/httpHelper";
import { Report_URL } from "../httpHelpers/urlContainer";
import { attachParams } from "../httpHelpers/urlHelper";

export const checkCarDetails = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.checkVehicle, data),
  });
};

export const requestOTP = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.sendOtp, data),
  });
};

export const verifyOTP = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.verifyOtp, data),
  });
};
export const getVersionHistory = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.versionList, data),
  });
};
export const getCarImageDetails = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.allReportDetails, data),
  });
};

export const getCarHealthReport = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.reportDetails, data),
  });
};
export const getLockUnlockDetails = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.lock_unlock, data),
  });
};
export const getVehicleDetails = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.vehicleDetails, data),
  });
};
export const getPackageDetails = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.packageDetails, data),
  });
};

export const getCityList = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.cityList, data),
  });
};
export const getRecommendedVehicles = async (data) => {
  return await getRequest({
    url: attachParams(Report_URL.recommendedVehicles, data),
  });
};
export const getVehicleDeals = async () => {
  return await getRequest({
    url: attachParams(Report_URL.carDeals),
  });
};
export const postContactDetails = async (data) => {
  return await postRequest({
    url: attachParams(Report_URL.contactDetails),
    data: data,
  });
};
export const postReportRequest = async (data) => {
  return await postRequest({
    url: attachParams(Report_URL.requestReport),
    data: data,
  });
};
