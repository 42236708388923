export const minPrice = 0;
export const maxPrice = 10000000; // REMOVE ONE ZERO

export const minKms = 0;
export const maxKms = 10000000; // REMOVE ONE ZERO

export const minYear = 2015;
export const maxYear = new Date().getFullYear();

export const minOwners = 1;
export const maxOwners = 10;
// export const yearDiff = maxYear - minYear;
