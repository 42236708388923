import React from "react";

function Cancellation() {
  return (
    <section className="commonConditions">
      <div className="container">
        <div className="smallContainer">
          <h1>CANCELLATION AND REFUND POLICY</h1>
          <div>
            <h2>1. DURING INITIAL 10 DAYS PERIOD</h2>
            <p>
              Every Warranty policy holder / Dealers who has purchased extended
              warranty policy from Wisedrive Technologies Private Limited may
              request the cancellation of the policy and full refund thereof on
              or before the expiry of 10 (ten) calendar days from the date of
              receipt of Warranty policy premium amount. The Warranty Holder
              /Dealers shall place such policy cancellation and refund request
              by way of writing an email to Wisedrive Technologies Private
              Limited on customercare@wisedrive.in (“Cancellation Request”).
              Upon receipt of such Cancellation Request within above mentioned
              period, the Warranty policy will be cancelled by Wisedrive
              Technologies Private Limited and the entire policy amount paid by
              the Warranty Policy Holder /Dealers will be refunded. It is hereby
              clarified that, the Warranty and all the rights under the Warranty
              Policy shall stand terminated and extinguished immediately from
              the date of the Cancellation Request. No claims shall be
              entertained by Wisedrive Technologies Private Limited from the
              Warranty Policy Holder/Dealers from the date of the Cancellation
              Request.
            </p>
          </div>
          <div>
            <h2>2. CANCELLATION REQUEST RECEIVED AFTER INITIAL 10 DAYS</h2>
            <p>
              The Warranty Policy Holders are entitled to receipt of refund upon
              cancellation of the Warranty policy within 10 days from the
              receipt of premium only. The Warranty Policy Holder shall have the
              right to place a policy cancellation request after above mentioned
              days, however for any such Cancellation Request received from the
              Warranty Policy Holder , no refunds will be processed by Wisedrive
              Technologies Private Limited.
            </p>
          </div>
          <div>
            <h2>3. PAYMENT AND MODE OF REFUND</h2>
            <p>
              For the Cancellation Requests made within 10 days of payment,
              refund will be made within 14 (fourteen) business days from the
              date of receipt of the Cancellation Request. Such refund will be
              processed through the original mode of payment i.e. to the payment
              mode used initially by the Warrant Policy Holder or Wisedrive
              Technologies Private Limited may at its discretion process the
              refund through any other mode upon receipt of specific instruction
              from the Warranty Policy Holder along with the requisite details
              in the respective Cancellation Request.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cancellation;
