import React, { useEffect, useState } from "react";
import Hero from "../../assets/images/reports/RequestReport.png";
import MobileHero from "../../assets/images/reports/MobileHero.png";
import Close from "../../assets/images/home/close.svg";
import {
  getCityList,
  postReportRequest,
} from "../../services/getInspectionReport";
import { checkIsValidFormat } from "../../utils/commonFunctions";
import { ValidationContainer } from "../../utils/constants";

function RequestReport({ handleModel,carNumber }) {
  // const [count, setCount] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [unlockCount, setUnlockCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [dealerData, setDealerData] = useState({
    vehicleNum: carNumber,
    dealerName: "",
    delaerNum: "",
    dealercityId: "",
    dealerAddress: "",
  });
  const [dealerDataErr, setDealerDataErr] = useState({
    dealerName: "This field cannot be empty",
    delaerNum: "This field cannot be empty",
    dealercityId: "This field cannot be empty",
    dealerAddress: "This field cannot be empty",
  });
  const [customerData, setCustomerData] = useState({
    vehicleNum: carNumber,
    cusName: "",
    cusPhoneNum: "",
    custCityId: "",
    cusAddress: "",
  });
  const [customerDataErr, setCustomerDataErr] = useState({
    cusName: "This field cannot be empty",
    cusPhoneNum: "This field cannot be empty",
    custCityId: "This field cannot be empty",
    cusAddress: "This field cannot be empty",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (unlockCount === 1) {
      setDealerData({
        ...dealerData,
        dealerName: "",
        delaerNum: "",
        dealercityId: "",
        dealerAddress: "",
      });
      setCustomerData({ ...customerData, [name]: value });
      if (value !== "") {
        if (name === "cusPhoneNum") {
          if (
            checkIsValidFormat(value, ValidationContainer.mobileNumberFormat)
          ) {
            setCustomerDataErr({ ...customerDataErr, [name]: null });
          } else {
            setCustomerDataErr({
              ...customerDataErr,
              [name]: "Enter valid phone number",
            });
          }
        } else {
          setCustomerDataErr({ ...customerDataErr, [name]: null });
        }
      } else {
        setCustomerDataErr({
          ...customerDataErr,
          [name]: "This field cannot be empty",
        });
      }
    } else if (unlockCount === 0) {
      setCustomerData({
        ...customerData,
        cusName: "",
        cusPhoneNum: "",
        custCityId: "",
        cusAddress: "",
      });
      setDealerData({ ...dealerData, [name]: value });
      if (value !== "") {
        if (name === "delaerNum") {
          if (
            checkIsValidFormat(value, ValidationContainer.mobileNumberFormat)
          ) {
            setDealerDataErr({ ...dealerDataErr, [name]: null });
          } else {
            setDealerDataErr({
              ...dealerDataErr,
              [name]: "Enter valid phone number",
            });
          }
        } else {
          setDealerDataErr({ ...dealerDataErr, [name]: null });
        }
      } else {
        setDealerDataErr({
          ...dealerDataErr,
          [name]: "This field cannot be empty",
        });
      }
    }
  };
  const postData = async () => {
    setShowError(true);
    if (unlockCount === 1) {
      if(customerData.cusName||customerData.cusPhoneNum||customerData.custCityId||customerData.cusAddress||checkIsValidFormat(customerData.cusPhoneNum)){
        const response = await postReportRequest({ ...customerData });
        setCustomerData({
          ...customerData,
          cusName: "",
          cusPhoneNum: "",
          custCityId: "",
          cusAddress: "",
        });
      }
      else{
        setShowError(true)
      }
     
    } else if (unlockCount === 0) {
      if (
        !dealerData.dealerName ||
        !dealerData.delaerNum ||
        !dealerData.dealercityId ||
        !dealerData.dealerAddress ||
        !checkIsValidFormat(
          dealerData.delaerNum,
          ValidationContainer.mobileNumberFormat
        )
      ) {
        setShowError(true);
      } else {
        const response = await postReportRequest({ ...dealerData });
        setDealerData({
          ...dealerData,
          dealerName: "",
          delaerNum: "",
          dealercityId: "",
          dealerAddress: "",
        });
      }
    }
  };


  const hendleCityList = async () => {
    let response = await getCityList();
    if (response?.data?.responseType === "200") {
      setCityList(response?.data?.response?.CityList);
    }
  };
  useEffect(() => {
    hendleCityList();
  }, []);
  return (
    <div className="kycContainer">
      <div className="kycContent">
        <div className="rr-container rr-overflow">
          <div className="rr-img-container desktop">
            <img className="rr-img" src={Hero} alt="" />
          </div>
          <div className="rr-data-container ">
            <div className="rr-titlebox">
              <h3 className="rr-title desktop">
                Request warranty assessment Report
              </h3>
              <h3 className="rr-title mobile">Fetch car report</h3>
              <img
                className="cursor-pointer"
                onClick={handleModel}
                src={Close}
                alt=""
              />
            </div>
            <div className="rr-img-container mobile">
              <img className="rr-img" src={MobileHero} alt="" />
            </div>
            <div className="w-80">
              <div className="unlockTab">
                <div
                  className={`${
                    unlockCount === 0
                      ? "unlockTitile current rr-tab-title-active"
                      : "unlockTitile rr-tab-title"
                  }`}
                  onClick={() => {
                    setUnlockCount(0);
                    setShowError(false);
                    setDealerDataErr({
                      dealerName: "This field cannot be empty",
                      delaerNum: "This field cannot be empty",
                      dealercityId: "This field cannot be empty",
                      dealerAddress: "This field cannot be empty",
                    });
                    setDealerData({
                      vehicleNum: "test",
                      dealerName: "",
                      delaerNum: "",
                      dealercityId: "",
                      dealerAddress: "",
                    })
                  }}
                >
                  <h3>Dealer car</h3>
                </div>
                <div
                  className={`${
                    unlockCount === 1
                      ? "unlockTitile current rr-tab-title-active"
                      : "unlockTitile rr-tab-title"
                  }`}
                  onClick={() => {
                    setUnlockCount(1);
                    setShowError(false);
                    setCustomerDataErr({
                      cusName: "This field cannot be empty",
                      cusPhoneNum: "This field cannot be empty",
                      custCityId: "This field cannot be empty",
                      cusAddress: "This field cannot be empty",
                    })
                    setCustomerData({
                      vehicleNum: "test",
                      cusName: "",
                      cusPhoneNum: "",
                      custCityId: "",
                      cusAddress: "",
                    })
                  }}
                >
                  <h3 className="rr-tab-title">Own car</h3>
                </div>
              </div>
              <div className="tabData">
                {unlockCount === 0 && (
                  <div className="group-input">
                    <div className="w-100">
                      <label className="rr-label">Dealer Name</label>
                      <div className="rr-input-container">
                        <input
                          name="dealerName"
                          onChange={(e) => handleInput(e)}
                          value={dealerData.dealerName}
                          placeholder="Enter dealer name"
                          className="rr-input"
                          type="text"
                        />
                      </div>
                      {dealerDataErr.dealerName && showError && (
                        <p className="err">{dealerDataErr.dealerName}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">Dealer phone number</label>
                      <div className="rr-input-container">
                        <span>+91</span>
                        <div className="line"></div>
                        <input
                          name="delaerNum"
                          onChange={handleInput}
                          placeholder="ex. 9123456780"
                          className="rr-input"
                          type="text"
                          value={dealerData.delaerNum}
                        />
                      </div>
                      {dealerDataErr.delaerNum && showError && (
                        <p className="err">{dealerDataErr.delaerNum}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">Dealer city</label>
                      <div className="rr-input-container">
                        <select
                          name="dealercityId"
                          onChange={handleInput}
                          className="rr-input rr-select"
                          placeholder="Choose city"
                          value={dealerData.dealercityId}
                        >
                          <option
                            className="rr-placehoder-color"
                            value=""
                            hidden
                          >
                            Choose city
                          </option>
                          {cityList.map((item) => (
                            <option value={item.city_id}>{item.city}</option>
                          ))}
                        </select>
                      </div>
                      {dealerDataErr.dealercityId && showError && (
                        <p className="err">{dealerDataErr.dealercityId}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">Dealer address</label>
                      <div className="rr-input-container-textarea">
                        <textarea
                          value={dealerData.dealerAddress}
                          name="dealerAddress"
                          onChange={handleInput}
                          className="rr-input"
                          placeholder="Enter dealer address"
                        ></textarea>
                      </div>
                      {dealerDataErr.dealerAddress && showError && (
                        <p className="err">{dealerDataErr.dealerAddress}</p>
                      )}
                    </div>
                    <div></div>
                    <button
                      onClick={postData}
                      className="cta-button cursor-pointer"
                    >
                      Request report
                    </button>
                  </div>
                )}

                {unlockCount === 1 && (
                  <div className="group-input">
                    <div className="w-100">
                      <label className="rr-label">Name</label>
                      <div className="rr-input-container">
                        <input
                          name="cusName"
                          onChange={handleInput}
                          placeholder="Enter name"
                          className="rr-input"
                          type="text"
                          value={customerData.cusName}
                        />
                      </div>
                      {customerDataErr.cusName && showError && (
                        <p className="err">{customerDataErr.cusName}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">Phone number</label>
                      <div className="rr-input-container">
                        <span>+91</span>
                        <div className="line"></div>
                        <input
                          name="cusPhoneNum"
                          onChange={handleInput}
                          placeholder="ex. 9123456780"
                          className="rr-input"
                          type="text"
                          value={customerData.cusPhoneNum}
                        />
                      </div>
                      {customerDataErr.cusPhoneNum && showError && (
                        <p className="err">{customerDataErr.cusPhoneNum}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">City</label>
                      <div className="rr-input-container">
                        <select
                          name="custCityId"
                          onChange={handleInput}
                          className="rr-input rr-select"
                          placeholder="Choose city"
                          value={customerData.custCityId}
                        >
                          <option value="" hidden>
                            Choose city
                          </option>
                          {cityList.map((item) => (
                            <option value={item.city_id}>{item.city}</option>
                          ))}
                        </select>
                      </div>
                      {customerDataErr.custCityId && showError && (
                        <p className="err">{customerDataErr.custCityId}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <label className="rr-label">Address</label>
                      <div className="rr-input-container-textarea">
                        <textarea
                          value={customerData.cusAddress}
                          name="cusAddress"
                          onChange={handleInput}
                          className="rr-input"
                          placeholder="Enter dealer address"
                        ></textarea>
                      </div>
                      {customerDataErr.cusAddress && showError && (
                        <p className="err">{customerDataErr.cusAddress}</p>
                      )}
                    </div>
                    <div></div>
                    <button
                      onClick={postData}
                      className="cta-button cursor-pointer"
                    >
                      Request report
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestReport;
