import React, { useEffect, useState } from 'react';
import './benifitsNew.css';
import { Button, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EastIcon from '@mui/icons-material/East';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BlueTick from '../../assets/images/benifitsIcons/blueTickCircle.png';
import GreenTick from '../../assets/images/benifitsIcons/greenTick.png';
import OrangeTick from '../../assets/images/benifitsIcons/orangeTick.png';
import DoneIcon from '@mui/icons-material/Done';

const basicChips = [
  '#Engine',
  '#Transmission',
  '#OBD reports',
  '#Mechanical parts',
  '#Electrical parts',
  '#ECM Module',
  '#BCM Module',
  '#TCM Module',
  '#Accident History',
  '#Police verification',
];
const warrantyChips = [
  '#Up to 10 Lakhs',
  '#Engine',
  '#Transmission',
  '#AC Compressor',
  '#Pan India Warranty',
  '#Roadside Assistance',
  '#Loan protection Guarantee',
  '#Buyback Guarantee',
];
const freedomChips = [
  '#Periodic Maintenance',
  '#Showroom Service',
  '#Network Garages',
  '#All cities in India',
  '#24/7 Support',
  '#AC Service',
  '#Health Check ups',
  '#Running Maintenance issues',
];
function Benifits({ handleScrollGrade }) {
  const [isMobile, setIsMobile] = useState(false);
  const [benifitsSelected, setBenifitsSelected] = useState('inspection');

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);
  }, []);
  const handleDelete = () => {
    alert('test');
  };
  const handleOpenWhatsApp = () => {
    const phoneNumber = '7338073380';
    const message = 'Hi'; // Optional message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the URL in a new tab or window
    window.open(url, '_blank');
  };
  return (
    <section className="benifits-container">
      <div className="container" style={{ marginBottom: '25px' }}>
        <p className="benifits-note">
          Our inspection has 200+ check points including advanced AI with Engine
          sound analysis, first ever technology in the world.
        </p>
      </div>
      <div className="container">
        <div>
          <div>
            <h2 className="h2MobileSize">
              We’ve covered everything to protect your car and Investment.
            </h2>

            {/* <div className="btn-insurance-conatiner">
              <Button
                onClick={() => setBenifitsSelected('inspection')}
                className={
                  benifitsSelected === 'inspection'
                    ? 'btn-insurance insurance-active'
                    : 'btn-insurance'
                }
                variant="contained"
                endIcon={
                  benifitsSelected === 'inspection' && (
                    // <ChevronRightIcon sx={{ width: '25px', height: '25px' }} />
                    <img src={BlueTick} width="35px"></img>
                  )
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  Physical Inspection
                  <br />
                  <span style={{ fontFamily: 'matter' }}>135 Check Points</span>
                </div>
              </Button>
              <Button
                onClick={() => setBenifitsSelected('warranty')}
                className={
                  benifitsSelected === 'warranty'
                    ? 'btn-insurance insurance-active-green'
                    : 'btn-insurance'
                }
                variant="contained"
                endIcon={
                  benifitsSelected === 'warranty' && (
                    <img src={GreenTick} width="35px"></img>
                  )
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  RTO and Police Verification
                  <br />
                  <span style={{ fontFamily: 'matter' }}>29 Check Points</span>
                </div>
              </Button>
              <Button
                onClick={() => setBenifitsSelected('freedom')}
                className={
                  benifitsSelected === 'freedom'
                    ? 'btn-insurance insurance-active-orange'
                    : 'btn-insurance'
                }
                variant="contained"
                endIcon={
                  benifitsSelected === 'freedom' && (
                    <img src={OrangeTick} width="35px"></img>
                  )
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  Advanced OBD-2 Scanner
                  <br />
                  <span style={{ fontFamily: 'matter' }}>76 Check Points</span>
                </div>
              </Button>
            </div> */}
          </div>
          {/* <div>
            <div className="btn-insurance-container">
              <div className="btn-insurancem1">
                <Button
                  onClick={() => setBenifitsSelected('inspection')}
                  className={
                    benifitsSelected === 'inspection'
                      ? 'btn-insurance-mobile insurance-active'
                      : 'btn-insurance-mobile'
                  }
                  variant="contained"
                >
                  Physical Inspection
                </Button>
                <Button
                  onClick={() => setBenifitsSelected('warranty')}
                  className={
                    benifitsSelected === 'warranty'
                      ? 'btn-insurance-mobile insurance-active-green'
                      : 'btn-insurance-mobile'
                  }
                  variant="contained"
                >
                  RTO and Police Verification
                </Button>
              </div>
              <Button
                onClick={() => setBenifitsSelected('freedom')}
                className={
                  benifitsSelected === 'freedom'
                    ? 'btn-insurance-mobile insurance-active-orange'
                    : 'btn-insurance-mobile'
                }
                variant="contained"
              >
                Advanced OBD 2 Scanner Check
              </Button>
            </div>

            {benifitsSelected === 'inspection' && (
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Engine & Transmission
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        We check engine and transmission efficiency, performance
                        and overall condition along with potential failures.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Flood Vehicle Check, Major engine repair check
                    </span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Electrical Parts Inspection
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        End to end Wiring, Sensors, Lights, Power Windows,
                        Electronic side mirrors, and Infotainment systems check.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Battery function check, Odometer Tampering Check
                    </span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Mechanical Parts Inspection
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Suspension, Brakes, Mounting, Chassis damage, Tyres,
                        Steering, pedals and clutch function check
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Braking System, Fuel System Check
                    </span>
                  </div>
                </div>
                <div className="benifits-card">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Interior and Exterior check
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Dashboard, Upholstery, Carpet, Floor Mats, Doors,
                        Windows, Lubricants and Fluids check.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Accident Check, Body panel repaint or replacement check
                    </span>
                  </div>
                </div>
              </div>
            )}

            {benifitsSelected === 'warranty' && (
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card insurance-active-green benifits-full ">
                  <div>
                    <div className="benifits-card-content-full">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Loan & Insurance Check
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Check Financier details and NOC status of hypothecation
                        and insurance validity and expiry check.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterBold',
                      }}
                    >
                      + Loan NOC Check, Insurance Expiry Check
                    </span>
                  </div>
                </div>
                <div className="benifits-card insurance-active-green">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Traffic Fines and Police Blacklist Check
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Traffic fines and police blacklist status check by state
                        police department.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterBold',
                      }}
                    >
                      + Blacklist Status Check
                    </span>
                  </div>
                </div>
                <div className="benifits-card insurance-active-green">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Owners and Vehicle info check
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Current ownership status check, RC card cross
                        verification with centralised RTO database.
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterBold',
                      }}
                    >
                      + Fraud data verification
                    </span>
                  </div>
                </div>
              </div>
            )}

            {benifitsSelected === 'freedom' && (
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card insurance-active-orange benifits-full ">
                  <div>
                    <div className="benifits-card-content-full">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Engine Control Module (ECM) & Transmission Control
                          Module (TCM)
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Advanced engine control and functions check. Ignition
                        system, throttle body check, misfire check, Fuel
                        injection check. Advanced Transmission module check for
                        manual and automatic gear shifting with solenoid
                        operations check
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterBold',
                      }}
                    >
                      + Engine failure probability Analysis, Transmission
                      Failure Probability Analysis
                    </span>
                  </div>
                </div>
                <div className="benifits-card insurance-active-orange">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Body Control Module (BCM)
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        Advanced Body Control check for stability, traction and
                        cruise control management at high speeds
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Stable driving check at high speeds
                    </span>
                  </div>
                </div>
                <div className="benifits-card insurance-active-orange">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <span className="card-highlet">
                          Fault Code check and Analysis
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <p>
                        P-Codes, B-Codes, C-Codes check, symptoms, potential
                        cause and probable solution analysis
                      </p>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <span
                      style={{
                        fontFamily: 'matterMedium',
                      }}
                    >
                      + Expensive Repair prevention check
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          {/* New */}
          <div>
            <div className="insurance-card-conatiner">
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <div className="product-item-heading-left">
                          <h5 style={{ fontSize: '24px', color: 'white' }}>
                            135
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              paddingTop: '0px',
                              fontFamily: 'matterThin',
                              color: 'white',
                              paddingLeft: '0px !important',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Check Points
                          </p>
                        </div>
                        <span className="card-highlet">
                          Engine & Transmission
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Engine & Transmission
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Electrical Parts Inspection
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Mechanical parts Inspection
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Interior and Exterior check
                        </span>
                      </Typography>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <Typography>
                      + Flood Vehicle Check, Major engine repair check
                    </Typography>
                    <Typography>
                      + Battery function check, Odometer Tampering Check
                    </Typography>
                    <Typography>+ Braking System, Fuel System Check</Typography>
                  </div>
                </div>
              </div>
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card insurance-active-orange">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <div className="product-item-heading-left">
                          <h5 style={{ fontSize: '24px', color: 'white' }}>
                            76
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              paddingTop: '0px',
                              fontFamily: 'matterThin',
                              color: 'white',
                              whiteSpace: 'nowrap',
                              paddingLeft: '0px !important',
                            }}
                          >
                            Check Points
                          </p>
                        </div>
                        <span className="card-highlet">
                          Advanced OBD 2 Scan Check
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Engine Control Module (ECM)
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Transmission Control Module (TCM)
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Body Control Module (BCM)
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Fault Code check and Analysis
                        </span>
                      </Typography>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <Typography>
                      + Engine failure probability Analysis, Transmission
                      Failure Probability Analysis
                    </Typography>
                    <Typography>
                      + Stability driving check at high speeds using Advanced
                      OBD-2 scanner
                    </Typography>
                    <Typography>+ Expensive Repair prevention check</Typography>
                  </div>
                </div>
              </div>
              <div className="benifits-cards-wrap tab-active ">
                <div className="benifits-card insurance-active-green ">
                  <div>
                    <div className="benifits-card-content">
                      <h6 className="product-item-heading">
                        <div className="product-item-heading-left">
                          <h5 style={{ fontSize: '24px', color: 'white' }}>
                            29
                          </h5>
                          <p
                            style={{
                              fontSize: '12px',
                              paddingTop: '0px',
                              fontFamily: 'matterThin',
                              color: 'white',
                              whiteSpace: 'nowrap',
                              paddingLeft: '0px !important',
                            }}
                          >
                            Check Points
                          </p>
                        </div>
                        <span className="card-highlet">
                          RTO and Police Verification
                        </span>
                      </h6>
                    </div>
                    <div className="benifits-card-content-base">
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Loan & Insurance Check
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Traffic Fines and Police Blacklist Check
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          Owners and Vehicle info check
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            fontFamily: 'matterThin',
                            flexDirection: 'row',
                            gap: '5px',
                            color: '#0c286a',
                          }}
                        >
                          <DoneIcon />
                          RC Document Validation
                        </span>
                      </Typography>
                    </div>
                  </div>
                  <div className="benifits-card-content-footer-btn">
                    <Typography>
                      + Loan NOC Check, Insurance Expiry Check
                    </Typography>
                    <Typography>+ Blacklist Status Check</Typography>
                    <Typography>
                      + Fraud data and RC Document data verification with Vaahan
                      Database
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-contactUs">
        <div className="sticky-contactUs-container">
          <p
            style={{
              paddingTop: '3px',
              color: 'white',
              fontFamily: 'matter',
            }}
          >
            Contact us on{' '}
            <span style={{ fontFamily: 'matterSemiBold' }}>
              WhatsApp at 73380 73380{' '}
            </span>
            for any queries 24/7. Happy to Assist.
          </p>
          <Button
            variant="text"
            sx={{ color: 'white' }}
            startIcon={
              <WhatsAppIcon
                sx={{ width: '25px', height: '25px', color: 'white' }}
              />
            }
            endIcon={
              <EastIcon
                sx={{ width: '25px', height: '25px', color: 'white' }}
              />
            }
            onClick={() => handleOpenWhatsApp()}
          >
            Open Whatsapp
          </Button>
        </div>
        <div className="sticky-contactUs-container-mobile ">
          <div
            style={{
              paddingTop: '3px',
              color: 'white',
              fontFamily: 'matter',
              // fontSize: '16px',
            }}
          >
            <a href="#inspection-plans" style={{ marginLeft: '20%' }}>
              <Button
                sx={{
                  color: 'white',
                  fontSize: '20px',
                  fontFamily: 'matterMedium',
                }}
              >
                Book Inspection
              </Button>
            </a>
          </div>
          <p
            style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              paddingTop: '0px',
              gap: '5px',
              height: '60px',
              backgroundColor: 'green',
              width: '115px',
              justifyContent: 'center',
            }}
            onClick={() => handleOpenWhatsApp()}
          >
            Chat
            <WhatsAppIcon
              sx={{ width: '25px', height: '25px', color: 'white' }}
            />
          </p>
        </div>
      </div>
    </section>
  );
}
export default Benifits;
