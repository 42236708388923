import React, { useRef, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer/index';
import ContactUs from '../../components/contact-us/contact-us';
import Customers from '../../components/customers/customers';
import Price from '../../components/price/price';
import { useContext } from 'react';
import { MyContext } from '../../context';
import './home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import axios from 'axios';
import { SendIpOnHomeLoad } from '../../Api/PortalApis';
import { PortalContext } from '../../context/carPortalContext';
import { useLocation } from 'react-router-dom';
import ShowRoomGrade from '../../components/brand-grade/brandGrade';
import PlanDetails from '../../components/plan-details/planDetails';
import '../../styles/common.css';
import '../../styles/muiStyles.css';
import Benifits from '../../components/benifits/benifits';
import Hero from '../../components/hero/hero';
import Faq from '../faq';
import Compare from '../../components/compare/compare';
import QueryComponent from '../../components/queryComponent/queryComponent';
import OnlyWhatsApp from '../../components/only-whatsApp/onlyWhatsApp';
import Locations from '../../components/locations/locations';
import IndexPlan from '../../components/plan-details';

const Home = () => {
  const { setHeightAct, setPlansContent } = useContext(MyContext);
  const [loader, setLoader] = useState(true);
  const [inspectionPlan, setInspectionPlan] = useState([]);
  const [warrantyPlan, setWarrantyPlan] = useState([]);
  const tracking = useTracking();
  const portalCtx = useContext(PortalContext);

  const location = useLocation();

  const warrantyRef = useRef(null);

  window.addEventListener('scroll', () => {
    let bannerHeight =
      document.getElementsByClassName('userSection')[0]?.clientHeight;
    let saveTimeHeight =
      document.getElementsByClassName('technology')[0]?.clientHeight;
    let planHeight =
      document.getElementsByClassName('suitablePlans')[0]?.clientHeight;
    let savingHeight =
      document.getElementsByClassName('CS-container')[0]?.clientHeight;
    let warrantyTableHeight =
      document.getElementsByClassName('engineProtection')[0]?.clientHeight;
    let priceHeight =
      document.getElementsByClassName('inspection')[0]?.clientHeight;
    let serviceHeight =
      document.getElementsByClassName('partnerLocation')[0]?.clientHeight;
    let calculateHeight =
      document.getElementsByClassName('checkUp')[0]?.clientHeight;
    let customersHeight =
      document.getElementsByClassName('guarenty')[0]?.clientHeight;

    let progress = document.getElementById('progress10');

    let sectionsHeight =
      bannerHeight +
      saveTimeHeight +
      planHeight +
      savingHeight +
      warrantyTableHeight +
      priceHeight +
      serviceHeight +
      calculateHeight;
    if (window.pageYOffset >= sectionsHeight) {
      setHeightAct(true);
      progress.classList.add('warrantyProgress');
      // console.log();
    }
  });

  const checkTimer = () => {
    let hours = 24; // if you want to clear after 1hrs simply change hours=1)
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
      }
    }
  };

  async function sendIpOnLoad() {
    try {
      // get ip address from the ipfy api
      const res = await axios.get('https://api.ipify.org/?format=json');
      console.log('res. ip api', res);
      if (res.status === 200) {
        const userIp = res.data.ip;
        portalCtx.setUserIp(userIp);
        const data = {
          ip: userIp,
        };
        try {
          const resp = await SendIpOnHomeLoad(data);
          if (resp.status === 200) {
          }
        } catch (error) {
          console.error('Sending ip address failed', error);
        }
      } else {
        throw new Error('Failed to get ip address from the ipfy api');
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    sessionStorage.clear();
    setLoader(false);
    // checkTimer();

    tracking.trackEvent({
      home: {
        isloaded: true,
      },
    });
    sendIpOnLoad();
  }, []);

  useEffect(() => {
    if (location.state && location.state.fromPlan) {
      // console.log("scrolling to warranty");
      // const element = document.querySelector("#warranty-section-scroll");
      // console.log(element);
      if (warrantyRef.current) {
        warrantyRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }, [location.state, warrantyRef.current]);

  return (
    !loader && (
      <div>
        <Header />
        {/* <Banner /> */}
        {/* <CustomerStory /> */}
        {/* New designs */}
        <Hero />
        <Benifits />
        <IndexPlan />
        {/* <PlanDetails /> */}
        <Compare />
        <OnlyWhatsApp />
        <ShowRoomGrade />
        <QueryComponent />

        <Price />
        <ContactUs />
        <Customers />
        <Faq />
        {/* new designs */}
        {/* <ContactUs /> */}
        <Footer />
        <Locations />
      </div>
    )
  );
};

export default Home;
