import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer";
import Header from "../../components/header";
import BuyBackPolicy from "./buyback_policy";

function index() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.wisedrive.in/buybackPolicy" />
      </Helmet>
      <Header />
      <BuyBackPolicy />
      <Footer />
    </div>
  );
}

export default index;
