import React from 'react';
import PlanDetailsNew from './planDetailsNew';

function IndexPlan() {
  return (
    <div>
      <PlanDetailsNew />
    </div>
  );
}
export default IndexPlan;
