import React, { useState } from "react";
import Verified from "../../assets/images/noReport/verified_user.svg";
import Car from "../../assets/images/noReport/car.png";
import Pin from "../../assets/images/noReport/location.svg";
import Refresh from "../../assets/images/noReport/refresh.svg";
import LoadCar from "../../assets/images/home/car.svg";
import { useNavigate } from "react-router-dom";
import { getRecommendedVehicles } from "../../services/getInspectionReport";
import { useEffect } from "react";
import { dateFormatChanger } from "../../utils/commonFunctions";

const Recommendations = () => {
  const navigate = useNavigate();
  const [recommended, setRecommended] = useState([]);
  const [page, setPage] = useState(1);

  const getRecommendedData = async () => {
    const response = await getRecommendedVehicles({ vehicleId: "", dealerId: "", pageNo: page });
    if (response?.data?.responseType === "200") {
      setRecommended([...recommended, ...response.data.response?.RecommendedVehicleList]);
    }
  };

  const handleReport = (car) => {
    sessionStorage.setItem("vehicleNo", car?.vehicle_no);
    sessionStorage.setItem("vehicleId", car?.vehicle_id);
    navigate(`/report?vehicleId=${car?.vehicle_id}`);
  };
  
  useEffect(() => {
    getRecommendedData();
  }, [page]);

  return (
    recommended.length > 0 && (
      <>
        <section className="recommendation">
          <div className="container">
            <div className="recommendationContainer">
              <div className="recommendationHeading">
                <h2>Recommendations</h2>
                <p>
                  <img src={Verified} alt="approved" /> Wisedrive pre-approved cars
                </p>
              </div>
              <div className="recommendationContent">
                {recommended.map((car, index) => {
                  return (
                    <div key={index} onClick={() => handleReport(car)} className="recommendationCard cursor-pointer">
                      <img src={car?.vehicle_front_image ? car?.vehicle_front_image : Car} alt="car" />
                      <div>
                        <h3>
                          {car?.vehicle_model} <span>{car?.manufacturing_year}</span>
                        </h3>
                        <label>{car?.vehicle_no}</label>
                      </div>
                      <div className="carSpecs">
                        <div>
                          <h4>Updated on</h4>
                          <p>{dateFormatChanger(car?.inspection_on, "DD-MM-YYYY")}</p>
                        </div>
                        <div>
                          <h4>Validity</h4>
                          <p>{dateFormatChanger(car?.validity, "DD-MM-YYYY")}</p>
                        </div>
                        <div>
                          <h4>Fuel</h4>
                          <p>{car?.fuel_type}</p>
                        </div>
                      </div>
                      <a>
                        <img className="location-icon" src={Pin} alt="location" />
                        {car?.dealer_location ? car?.dealer_location : "details not available"}
                      </a>
                    </div>
                  );
                })}
              </div>
              <div className="loadMore">
                <a onClick={() => setPage((page) => page + 1)} className="cursor-pointer">
                  <img src={LoadCar} alt="load cars" />
                  Load more cars
                  <img src={Refresh} alt="load cars" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default Recommendations;
