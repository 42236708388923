import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import TermsAndConditions from './terms-and-conditions'

function index() {
  return (
    <div>
      <Header />
      <TermsAndConditions />
      <Footer />
    </div>
  )
}

export default index
