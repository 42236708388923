import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import './modal.css';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   width: "40%",
//   // height: "90%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "ghostWhite",
//   border: "2px solid #fff !important",
//   boxShadow: 24,
//   borderRadius: 4,
//   p: 2,
// };

const BuyNowModal = ({
  open,
  handleClose,
  loaderOpen,
  handleApprove,
  message,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      // handleUpgradePlan={handleUpgradePlan}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box-css">
        <div>
          <div>
            <p
              className="modal-text-mobile"
              style={{ fontFamily: 'matterMedium', textAlign: 'center' }}
            >
              {message}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#003CC5', fontFamily: 'matterMedium' }}
              onClick={handleApprove}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default BuyNowModal;
