import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import PaymentStatus from './PaymentStatus';
import Compare from '../compare/compare';
import QueryComponent from '../queryComponent/queryComponent';
import BrandGrade from '../brand-grade/brandGrade';
import Price from '../price/price';
import Customers from '../customers/customers';
import Faqsection from '../../containers/faq/faqSection';
import Faq from '../../containers/faq';

const PlanBuyStatus = () => {
  return (
    <div>
      <Header />
      <PaymentStatus />
      <Faq />
      <Footer />
    </div>
  );
};

export default PlanBuyStatus;
