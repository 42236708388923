import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import AboutWiseDrive from "./about-wisedrive";

function index() {
  return (
    <div>
      <Header />
      <AboutWiseDrive />
      <Footer />
    </div>
  );
}

export default index;
