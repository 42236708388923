import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import ServiceAndMaintenance from './service-and-mainentance'

function index() {
  return (
    <div>
      <Header />
      <ServiceAndMaintenance />
      <Footer />
    </div>
  )
}

export default index
