export const BASE_URL = `http://164.52.217.96:30036`

export const Report_URL={
    checkVehicle:`${BASE_URL}/checkVehicle`,
    sendOtp:`${BASE_URL}/send/otptolead`,
    verifyOtp:`${BASE_URL}/verify/leadotp`,
    reportDetails:`${BASE_URL}/getInspection/reportDetails`,
    moduleList:`${BASE_URL}/getModuleList`,
    versionList:`${BASE_URL}/getInspection/versionList`,
    allReportDetails:`${BASE_URL}/getInspection/AllreportDetails`,
    vehicleDetails:`${BASE_URL}/vehicleDetails`,
    cityList:`${BASE_URL}/cityList`,
    recommendedVehicles:`${BASE_URL}/getRecommendedVehicles`,
    contactDetails:`${BASE_URL}/addContactUsDetails`,
    carDeals:`${BASE_URL}/carDeals`,
    requestReport:`${BASE_URL}/addInspectionReportRequest`,
    lock_unlock:`${BASE_URL}/lockunlockmoduleList`,
    packageDetails:`${BASE_URL}/getPackageDetails`,
}
Object.freeze(Report_URL);