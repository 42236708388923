import React, { useState } from "react";
import Arrow from "../../assets/images/home/orangeArrow.svg";
import Info from "../../assets/images/noReport/info.svg";
import Profile from "../../assets/images/noReport/lab_profile.svg";
import RequestReport from "../../components/popups/RequestReport";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const Banner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleModel = () => {
    setVisible(!visible);
  };
  return (
    <>
      <section className="reportBanner noReport">
        <div className="container">
          <div className="reportContentContainer">
            <div className="reportContainer">
              {/* <label>Report expires in 35 days</label> */}
              <div className="reportContent mb-20">
                <h2>{id}</h2>
              </div>
            </div>
            <p className="noReportPara">
              <img src={Info} alt="" /> We don't have enough data to generate report on this car
            </p>
            <div className="reportAccess">
              <div>
                <img src={Profile} alt="access" />
                <div style={{ display: "block" }}>
                  <p>Request wisedrive warranty assesment report of this car</p>
                  <span>
                    Wisedrive will asses the car health conditions & will generate report of this car for free.
                  </span>
                </div>
              </div>
              <a className="cursor-pointer" onClick={handleModel}>
                <span>Request report for free</span>
                <img src={Arrow} alt="" />
              </a>
            </div>
          </div>
          {visible && <RequestReport carNumber={id} handleModel={handleModel} />}
        </div>
      </section>
    </>
  );
};

export default Banner;
