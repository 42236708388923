import React from "react";

function BuyBackPolicy() {
  return (
    <section className="commonConditions">
      <div className="container">
        <div className="smallContainer">
          <h1>Wisedrive technologies private limited Buyback Guarantee disclaimer</h1>
          <div>
            <h2></h2>
            <p>NOW, THEREFORE, in consideration of the covenants and mutual promises contained herein and other good and valuable consideration, the receipt and legal sufficiency of which are hereby acknowledged, the parties agree as follows:</p>
          </div>
          <div>
            <h2>Vehicle Repurchase</h2>
            <p>
              <ul>
                <li>Subject to the terms and conditions of this Agreement, at the Closing (as defined below), the Company agrees to repurchase from Seller, and Seller agrees to sell to the Company, at the purchase price upto Rs 5,00,000/- for Hatchback, Up to Rs 10,00,000 for Sedan and up to Rs 15,00,000 for SUV owned by Seller, free and clear of all liens, claims, security interests and rights of third parties.</li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Closing</h2>
            <p>
              <ul>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Closing:
                  </span>
                  &nbsp; The closing (the “Closing”) of the transactions contemplated hereby shall take place at the offices of the Company, at L 75, 15th Cross Road, Sector 6, HSR Layout Bengaluru, Karnataka 560102 within three sixty five days after the date on which all of the conditions set forth in Section 2.4, to the extent not waived by the party entitled to the benefit of such conditions, are satisfied, or at such other time, place and date as mutually agreed to by the parties; provided, however, that if the Closing does not occur (in the absence of a party’s breach of its representations, warranties or covenants hereunder) by December 2023 (or such later date as may be agreed upon in writing by the parties), this Agreement shall terminate and be of no further force or effect and neither party shall have any liability or obligation hereunder; provided, that in the event of any such breach, termination shall be at the option of the non-breaching party; and provided further, that Sections 5.3, 6.1, 6.5, 6.9 (with respect to any disclosure by Seller) and 6.11 shall survive any termination hereof. The date of the Closing is referred to in this Agreement as the “Closing Date.”
                </li>
                <br />
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Closing Deliveries:
                  </span>
                  &nbsp;At the Closing:
                  <ul>
                    <li style={{ listStyleType: "disc", marginLeft: "50px" }}>Seller shall deliver or cause to be delivered to the Company vehicle for transfer and</li>
                    <li style={{ listStyleType: "disc", marginLeft: "50px" }}>The Company shall pay the Seller the Purchase Price immediately.</li>
                  </ul>
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Simultaneous Deliveries:
                  </span>
                  &nbsp; The delivery of vehicle required to be delivered at the Closing pursuant to this Agreement shall be deemed to occur simultaneously.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Conditions to Closing:
                  </span>
                  &nbsp;. The obligations of the Company and Seller, as applicable, under this Agreement, and the consummation of the transactions contemplated hereby, shall be subject to the satisfaction of the following conditions:
                </li>
                <ul>
                  <li style={{ listStyleType: "disc", marginLeft: "50px" }}>Each party shall have made the deliveries required by it pursuant to Section 2.2;</li>
                  <li style={{ listStyleType: "disc", marginLeft: "50px" }}>This Agreement and the transactions contemplated hereby shall have been approved by the Company;</li>
                  <li style={{ listStyleType: "disc", marginLeft: "50px" }}>The Company’s board of directors (or authorized committee thereof) shall have received a written opinion from the Director dated as of or prior to the Closing Date, to the effect that the consideration to be paid by the Company to consummate the transactions contemplated hereby is fair to the Company from a financial point of view, a copy of which shall have been delivered to the Company, and such opinion shall not have been withdrawn; and</li>
                  <li style={{ listStyleType: "disc", marginLeft: "50px" }}>Seller shall have approved (which approval shall not be unreasonably withheld or delayed) the Company’s press release contemplated in Section 6.9.</li>
                </ul>
              </ul>
            </p>
          </div>
          <div>
            <h2>Representations and Warranties of Seller</h2>
            <p>
              <ul>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Ownership of Vehicle; Title:
                  </span>
                  &nbsp;Seller is the sole record and beneficial owner of the Vehicle free and clear of all liens, claims, security interests, options, purchase rights, charges and restrictions.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Authority
                  </span>
                  &nbsp;Seller has requisite legal capacity and full power and authority to execute, deliver and perform Seller’s obligations under this Agreement. This Agreement has been duly and validly executed and delivered by Seller and constitutes a legal, valid and binding obligation of Seller, enforceable against Seller in accordance with its terms. The execution and delivery by Seller of, and the performance by Seller of his obligations under, this Agreement will not contravene any provision of applicable law, or any agreement or other instrument binding upon Seller or to which the Shares are subject, or any judgment, order or decree of any governmental body, agency or court having jurisdiction over Seller, and no consent, approval, authorization or order of, or qualification with, any governmental body or agency is required for the performance by Seller of his obligations under this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    No Brokers or Finders
                  </span>
                  &nbsp;. Seller has not incurred and will not incur, directly or indirectly, as a result of any action taken or permitted to be taken by or on behalf of Seller, any liability for brokerage or finders’ fees or agents’ commissions or similar charges in connection with the execution and performance of the transactions contemplated by this Agreement.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Representations and Warranties of the Company. </h2>
            <p>The Company represents and warrants to Seller as of the date hereof and as of the Closing Date as follows:</p>
            <p>
              <ul>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Authority:
                  </span>
                  &nbsp;. Subject to receipt of the board (or authorized committee) approval contemplated by Section 2.4(b), the Company has all requisite corporate power and authority to execute, deliver and perform its obligations under this Agreement. Upon receipt of such board (or authorized committee) approval, this Agreement shall be duly and validly executed and delivered by the Company and shall constitute a legal, valid and binding obligation of the Company, enforceable against the Company in accordance with its terms. The execution and delivery by the Company of, and the performance by the Company of its obligations under, this Agreement will not contravene any provision of its Certificate of Incorporation or Bylaws, applicable law, or any agreement or other instrument binding upon the Company, or any judgment, order or decree of any governmental body, agency or court having jurisdiction over the Company, and no consent, approval, authorization or order of, or qualification with, any governmental body or agency is required for the performance by the Company of its obligations under this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    No Brokers or Finders:
                  </span>
                  &nbsp;The Company has not incurred and will not incur, directly or indirectly, as a result of any action taken or permitted to be taken by or on behalf of the Company, any liability for broker’s or finder’s fees or agents’ commissions or similar charges in connection with the execution and performance of the transactions contemplated by this Agreement.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Covenants</h2>
            <p>
              <ul>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Release of Claims:
                  </span>
                  &nbsp;In further consideration of the parties’ execution, delivery and performance hereof, each party hereby expressly waives, releases, acquits and forever discharges the other party and its respective subsidiaries, affiliates, officers, directors, shareholders, employees, partners, agents, attorneys, representatives, successors, assigns, heirs and personal representatives (hereinafter referred to as the “Released Parties”), from any and all claims, demands and causes of action which such releasing party has or claims to have, known or unknown, of whatever nature, against the other party and/or the Released Parties, which exist or may exist as of, or at any time prior to, the Closing Date.
                </li>
              </ul>

              <li>
                <span style={{ borderBottom: "1px solid black", fontWeight: "800" }}>Seller Agrees ,</span>
                &nbsp;Seller agrees that it shall furnish only that portion of the Proprietary Information which it is advised in writing by counsel that Seller is legally required to disclose, and further, Seller shall exercise commercially reasonable efforts to obtain reliable assurance that confidential treatment will be accorded the Proprietary Information which is so disclosed.
              </li>
              <li>
                <span style={{ borderBottom: "1px solid black", fontWeight: "800" }}>Standstill Agreement:</span>
                &nbsp;. Seller will not, without the prior written consent of the Company, during the period beginning on the date of this Agreement and ending two years after the Closing Date:
              </li>
            </p>
          </div>
          <div>
            <h2>Miscellaneous</h2>
            <p>
              <ul>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Expenses:
                  </span>
                  &nbsp;Whether or not the transactions contemplated hereby are consummated, all costs and expense incurred in connection with this Agreement (including attorneys’ fees) and the transactions contemplated hereby shall be paid by the party incurring the expenses.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Assignment:
                  </span>
                  &nbsp;This Agreement and the rights, duties and obligations hereunder may not be assigned by either party without the prior written consent of the other party hereto, and any such attempted assignment without consent shall be void.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Cumulative Remedies:
                  </span>
                  &nbsp;The rights and remedies under this Agreement are cumulative and not exclusive of any other rights, remedies, powers and privileges that may be available under this Agreement or otherwise.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Successors and Assigns:
                  </span>
                  &nbsp;The terms and conditions of this Agreement shall inure to the benefit of and be binding upon the respective executors, administrators, heirs, permitted successors and permitted assigns of the parties. Except as expressly provided herein, nothing in this Agreement, express or implied, is intended to confer upon any party, other than the parties hereto, any rights, remedies, obligations or liabilities under or by reason of this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Governing Law:
                  </span>
                  &nbsp;This Agreement shall be governed by and construed in accordance with the laws of the State of Karnataka.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Counterparts:
                  </span>
                  &nbsp;This Agreement may be executed in two or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Headings:
                  </span>
                  &nbsp;The headings of the sections of this Agreement are for convenience only and shall not by themselves determine the interpretation of this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Notices:
                  </span>
                  &nbsp;. Any notice required or permitted hereunder shall be given in writing and shall be deemed effectively given upon personal delivery, twenty-four (24) hours after transmission by facsimile, or three (3) days after deposit in the United States mail, by registered or certified mail, addressed to the parties’ respective addresses set forth on the signature page hereof (or to such other address as such party may designate by ten (10) days’ advance written notice to the other party).
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Amendment or Waiver of Agreement:
                  </span>
                  &nbsp;The provisions of this Agreement may not be amended or waived except by a written instrument signed by the Company and Seller.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Legal Action and Fees:
                  </span>
                  &nbsp;In the event of any controversy, claim or dispute between the parties hereto arising out of or relating to this Agreement, the prevailing party shall be entitled to recovery from the other party its reasonable expense, including attorneys’ fees.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Entire Agreement:
                  </span>
                  &nbsp;The terms of this Agreement and other documents and instruments referenced herein are intended by the parties as a final expression of their agreement with respect to the subject matter hereof and thereof and may not be contradicted by evidence of any prior or contemporaneous agreement. The parties further intend that this Agreement constitutes the complete and exclusive statement of its terms and that no extrinsic evidence whatsoever may be introduced in any judicial proceeding, if any, involving this Agreement.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Invalid Provisions:
                  </span>
                  &nbsp;. If one or more provisions of this Agreement are held to be unenforceable under applicable law, (i) in the case of Section 5.6, such provisions shall be subject to judicial reformation in accordance with the terms of § 15.51(c) of the Karnatka Business and Commerce Code and (ii) in the case of any other provision, such provision shall be excluded from this Agreement and the balance of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms.
                </li>
                <li>
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      fontWeight: "800",
                    }}
                  >
                    Representation by Legal Counsel:
                  </span>
                  &nbsp;Each party is a sophisticated person or entity that was advised by experienced legal counsel and other advisors in the negotiation and preparation of this Agreement. As a result, neither this Agreement nor any provision herein shall be interpreted in favor of or against a party because such party or its legal counsel drafted this Agreement or such provision.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyBackPolicy;
