import React from "react";

function TermsAndConditions() {
  return (
    <section className="commonConditions">
      <div className="container">
        <div className="smallContainer">
          <h1 style={{ textAlign: "center" }}>
            Inspection Terms &amp; Condition
          </h1>
          <p>
            <strong>Our/We and Us </strong>
            means Wisedrive Technologies PVT LTD Limited
          </p>
          <p>
            <strong>Agreement </strong>means the agreement between Wisedrive and
            the Customer pursuant to which Wisedrive agrees to procure that an
            Inspector carries out an Inspection in accordance with these terms
            and conditions.
          </p>
          <p>
            <strong>Cancellation Fee </strong>means the amount payable by the
            Customer as referred in these terms and conditions.
          </p>
          <p>
            <strong>Customer/You </strong>mean a customer of Wisedrive who
            places an order for an Inspection.
          </p>

          <p>
            <strong>Fee </strong>means the amount payable by the Customer to
            Wisedrive for the Inspection as set out at{" "}
            <a href="https://www.wisedrive.in">https://www.wisedrive.co.in</a>
          </p>
          <p>
            <strong>Inspection </strong>means an audio visual external vehicle
            inspection and test drive carried out by an Inspector on behalf of
            the Wisedrive for the Customer in accordance with these terms and
            conditions.
          </p>
          <p>
            Wisedrive provides a number of different categories of Inspection,
            each of which involves different checks. Details of the different
            categories of inspection and the checks that are involved are
            available at{" "}
            <a href="https://www.wisedrive.in">https://www.wisedrive.co.in</a>
            &nbsp; or by telephoning 7353 34 35 36. Confirmation of the category
            of Inspection that the Customer has chosen to be carried out on the
            Vehicle.
          </p>
          <p>
            <strong>Inspector </strong>refers to the qualified inspector who
            carries out the Inspection on behalf of Wisedrive. Inspectors are
            subject to availability and may be supplemented by the use of
            appropriate third party agents.
          </p>
          <p>
            <strong>Report </strong>means the written report produced by the
            Inspector as part of the Inspection, which includes the
            Customer&rsquo;s name and address, registration details of the
            specific Vehicle inspected and its location, whether the Vehicle has
            passed or failed the Inspection, a list of checks carried out
            (depending on the type of Inspection which the Customer requires)
            and an outline of any areas of concern which the Inspector has
            decided should be brought to the attention of the Customer.
          </p>
          <p>
            <strong>Report Checklist </strong>means the list of areas which will
            be or have been checked by the Inspector as part of the Physical
            Inspection, which are available at{" "}
            <a href="https://www.wisedrive.in">https://www.wisedrive.co.in</a>
          </p>
          <p>
            <strong>Vehicle </strong>means the vehicle in respect of which the
            Customer requires an Inspector to carry out an Inspection.
          </p>

          <h2 style={{ marginTop: "1rem" }}>General </h2>

          <ol style={{ paddingTop: "0px" }}>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Inspections are prepared from visual and external checks of the
                Vehicle only and are limited to the parts and/or items
                identified on the Report. Inspections do not involve dismantling
                or disturbing any structure, assembly, component or internal
                mechanism. The Inspector will carry out a road test of the
                Vehicle and will produce a Report. There are certain parts of
                the Vehicle which will not be looked at. You should read the
                Report Checklist carefully to see what will not be checked.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                A copy of the Report will be sent to You by email to the email
                address You provide when ordering an Inspection. Due to the
                nature of the internet and the virus-protection software
                utilised by your internet service provider or loaded on to your
                computer, it may not be possible in all cases to guarantee
                delivery of the Report by email. In the event that You do not
                receive Your Report within 24 hours of the Wisedrive Vehicle
                Inspection taking place you must notify Us as soon as reasonably
                possible in order that We can resend Your Report to You. We
                shall not be liable to You for any failure to deliver a Report
                to the extent that non- delivery is outside of Our reasonable
                control.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Items which are listed on the Report Checklist as a part/item to
                be checked, but which are not reasonably accessible on the
                Vehicle, will not be inspected. In such circumstances, We will
                be under no obligation to inspect and/or report on the part/item
                in question and the Inspector will mark the relevant section of
                the Report accordingly.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                The vehicle should be reasonably clean to ensure that the
                Inspector can clearly see and accurately report on the relevant
                vehicle parts inspected. Where the vehicle is not clean, the
                Inspector reserves the right not to comment on those parts and
                will mark the Report Checklist accordingly. In such case, the
                Report Fee shall be fully payable.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>Exclusions</p>
              <p>
                <strong>The items We do not check are: </strong>
              </p>
              <ol>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    {" "}
                    Any parts which require dismantling/ removing/ disconnecting
                    or taking apart for inspection purposes;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Vehicle data checks</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Oil and fuel consumption;</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Source of oil leaks;</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Brake lining material (other than what is immediately
                    visible and can be seen without dismantling anything);
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Brake fluid for contamination. Brake fluid will deteriorate
                    over a period of time and We advise that it be replaced as
                    recommended by the Vehicle manufacturer;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Operation of cassette, compact-disc players,
                    TV/DVD/satellite navigation systems, Bluetooth or other
                    in-car entertainment;*
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Sound or radio reception and alarm systems;*</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Cylinder compression, vehicle electrics and electronics
                    which would require specific diagnostic equipment;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Exhaust emissions and/or catalytic converters. If this is
                    assessed by the Inspector as passing the Inspection it only
                    relates to confirming its external condition, gas tightness
                    and security. The Inspector is unable to confirm its
                    internal condition or effectiveness as this requires exhaust
                    emission testing which is not included in the Inspection;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    The accuracy of in-car computer systems (for example, but in
                    no way restricted to, computers used for route finding, fuel
                    efficiency or otherwise);
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Exhaust emissions;</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Air conditioning functions and efficiency*;</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    The habitation area of Motorhomes - The electrical systems
                    and appliances, water fittings and appliances, and the gas
                    fitting and appliances are not checked. These are beyond the
                    scope of the Inspection. It is strongly advised that these
                    are tested and checked by a suitably qualified person prior
                    to purchase;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Vehicle Technical Systems - Many vehicles are fitted or
                    equipped with automatic or computerised operating systems,
                    which may be controlled by microprocessors. These include
                    engine-management systems, fuel systems, ignition systems,
                    air- conditioning systems, lambda-controlled systems, stereo
                    radio, cassettes, amplifiers, compact discs (single and
                    multi), TV/DVD, satellite navigation, active and self-
                    levelling suspensions, gearboxes, turbo-chargers and
                    superchargers, anti-lock braking systems, four-wheel drive
                    and electronic lockable differential units, traction
                    control, alarm and immobiliser systems, catalytic exhaust
                    systems, and variable camshaft timing systems.
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Within the scope of the Inspection it is not possible to
                    check fully the detailed operation of these systems due to
                    the many variations in features and operating modes, and it
                    is not possible to confirm that these systems are
                    functioning fully to the manufacturers design
                    specifications. As a result, the Inspector is unable to
                    confirm the detailed operation of any of these systems other
                    than giving an overall view and opinion on the performances
                    generally inspected or checked. Therefore, We cannot be held
                    in any way liable to You for failing to identify any
                    malfunction or failure of any of these systems to perform to
                    the specification as designed by the manufacturer; and
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Non-standard accessories as a result of personal
                    modifications or otherwise.
                  </p>
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Furthermore, <strong>the Inspection cannot: </strong>
              </p>
              <ol>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    {" "}
                    a) confirm whether or not the passenger or luggage
                    compartments (including the
                    <br /> &nbsp;body or caravan area of any Motorhome) are
                    watertight; or
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    b) predict the life expectancy of exhaust systems, clutches
                    or dual-mass flywheels;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    c) provide any indication of any faults which might have
                    become apparent had the Vehicle been driven in excess of the
                    speed reached or the distance travelled during any road test
                    carried out by the Inspector;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    d) confirm that all or any of the components of the Vehicle
                    are original components, or replacement components that have
                    been manufactured by the manufacturer of the vehicle;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    e) include the inspection of any parts, components or areas
                    of the Vehicle that are not reasonably visible or accessible
                    to the Inspector at the time of the Inspection;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    f) confirm authenticity of the Vehicle&rsquo;s Registration
                    Document, the mileage, the Excise Licence, the Vehicle
                    Identification Number (VIN), the Engine Number, the
                    Registered Number or the Vehicle service history; or
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    g) check for any recall notices. Wisedrive recommends that
                    You check with the RTO or manufacturer that the Vehicle has
                    not been subject to such notices.
                  </p>
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                <strong>The Inspection can only </strong>describe and/or
                identify defects actually found and/or which are reasonably
                capable of being found upon an external visual examination of
                the Vehicle at the time of Inspection. We cannot be held liable
                and are not responsible for any latent defects which are later
                discovered unless We can reasonably have been expected to see
                them during an Inspection conducted within the parameters of
                these terms and conditions. We cannot advise of defects if We
                cannot see them, or they are not apparent during the Inspection
                of the Vehicle concerned. In particular, please note that
                (without limiting the foregoing) Vehicles over 5 years old,
                those that have a high mileage and/or have been subjected to
                abnormal use may have latent defects. While such defects may, in
                appropriate circumstances, give rise to a claim against the
                seller of the Vehicle, they fall outside the scope of the
                Inspection and the Report is provided on this basis. Neither
                Wisedrive nor the Inspector will have any liability to You as a
                result of such defects.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                <strong>Please note </strong>that used vehicles may contain
                serious internal, structural or mechanical defects and/or hidden
                corrosion, which will not be detectable from an external, visual
                examination. These vehicles may prove to be less reliable and
                may need more frequent repairs/servicing than more modern
                vehicles. While Our Inspectors take care to identify potential
                problems with the Vehicle from their external visual
                examination, You accept these and other disadvantages in owning
                a used vehicle and You accept that neither Wisedrive nor the
                Inspector will have any liability to You as a result of such
                defects.
              </p>
            </li>

            <li style={{ listStyleType: "disc" }}>
              <p>
                If there is no documentary evidence of a recent engine timing
                belt (cam belt) replacement, We recommend that the belt of the
                Vehicle is checked or replaced prior to purchase.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                If the Vehicle does not have a recent service history or it was
                not available to the Inspector at the time of the Inspection,
                then We recommend that a service in accordance with the Vehicle
                manufacturer&rsquo;s specifications be undertaken prior to
                purchase. This may highlight other defects, giving rise to
                repairs, which were not apparent from the external visual
                examination undertaken by the Inspector.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                <strong>Please note </strong>that the life expectancy of an
                exhaust or clutch system is uncertain and difficult to predict.
                The fact that such items have not been identified as faulty on
                the Report does not and should not be taken to imply that such
                systems will have a continuing life expectancy from the time of
                Our check.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                <strong>Road Testing </strong>
              </p>
              <ol>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    In order for our Inspectors to carry out a Road Test on the
                    Vehicle, You must ensure that the vehicle contains
                    sufficient:
                    <br /> (a) Water; <br /> (b) Oil;
                    <br /> (c ) Petrol.
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Road testing is carried out within the immediate area of the
                    Inspection. If:
                  </p>
                  <ol>
                    <li style={{ listStyleType: "disc" }}>
                      <p>The Vehicle is an unroadworthy condition; and/or</p>
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      <p>
                        The Vehicle contains insufficient oil, water and/or
                        petrol;
                      </p>
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      <p>
                        The Customer is unable to confirm that the Vehicle has a
                        valid fitness certificate and therefore a road test
                        cannot be carried out at the time of Inspection,
                      </p>
                    </li>
                  </ol>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    The Fee for the Inspection shall remain fully payable and an
                    additional Fee will be payable for any subsequent road test
                    You ask Us to undertake.
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    The carrying out of a road test does not mean that Our
                    Inspector has seen a valid fitness certificate.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <h2 style={{ marginTop: "1rem" }}>
            Obtaining necessary permissions, access to Vehicle etc{" "}
          </h2>
          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                It is the Customer&rsquo;s responsibility to, and the Customer
                must, ensure that proper provision is made (including the
                obtaining of any necessary permission from the owner of the
                Vehicle) for the Inspection to be carried out on garage premises
                or on an off-road site with an adequate level, hard, dry
                standing area. Our Inspector needs enough room to be able to
                walk round the Vehicle and fully open all doors. There must also
                be enough room for Our Inspector to park his own vehicle close
                to the Inspection site.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Good quality indoor lighting or daylight (if the Vehicle is
                outside) must be provided in the location where the Inspection
                is carried out. We are unable to check Vehicles in poor light or
                darkness.
              </p>
            </li>
          </ol>

          <h2 style={{ marginTop: "1rem" }}>Fees and cancellations </h2>

          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                The Fee must be paid during the time of booking the Inspection.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                We reserve the right to refuse to carry out an Inspection or to
                issue a Report on any vehicle: the Inspector cannot reasonably
                access; or appears to the Inspector to be: <br /> (a) not of
                standard production (for example, but not limited to, kit cars);{" "}
                <br /> (b) modified or not in regular use (for example, which
                show signs of excessive corrosion on moving parts associated
                with being left standing and which may result in the Vehicle
                being unsafe to drive without rectification); <br /> (c)
                un-roadworthy or unsafe to drive; or
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>Any of the circumstances referred to in these T&amp;C</p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Where the Customer has failed to ensure that the proper
                provision has been made for the Inspection to be carried out, as
                required; or
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Where the Customer has provided incorrect or incomplete
                information that has prevented Us from carrying out an
                Inspection and You acknowledge that in such circumstances We are
                entitled to treat the Agreement as terminated and to retain the
                Fee in full. If You wish Us to carry out a further Inspection,
                You will be required to pay a further Fee at the time of
                booking.
              </p>
            </li>

            <li style={{ listStyleType: "disc" }}>
              <p>
                You have the right to cancel an Inspection and receive a full
                refund of the Fee, 24hrs before the inspection is scheduled.
                Once the inspection is carried out no refund will be issued.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                To cancel an Inspection, You must inform Our Service Support
                Team of your decision to cancel by emailing Support@wisedrive.in
                or by calling 7353 34 35 36, Monday to Friday 9am to 5pm. To
                enable us to find Your order, you will need to provide Your
                name, address, telephone number and booking reference (as
                provided with your booking confirmation) together with Your
                cancellation request.
              </p>
            </li>
          </ol>

          <h2 style={{ marginTop: "1rem" }}>Complaints </h2>

          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                If You have a complaint or are dissatisfied with any Inspection
                carried out, please contact Us on 73533 334 35 36 as soon as
                possible. We may need to re-inspect the Vehicle to deal with the
                complaint and You agree to allow Us to do so.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                No repairs should be effected or any parts replaced on the
                Vehicle which is the subject of a complaint before the Vehicle
                has been re-inspected by Us. Should emergency repairs be
                required to make the Vehicle safe and which is then later to be
                the subject of a complaint to Us, then You must ensure that a
                complaint is made to Us (including notification of the need to
                carry out emergency repairs) within a reasonable time after
                discovering the problem, and that any faulty or worn parts which
                are repaired or replaced are retained for future re-examination
                by Us. We will not, under any circumstances, be responsible for
                the cost of any repairs carried out or parts replaced without
                Our previous agreement in writing.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                We reserve the right to require another of Our Inspectors to
                re-inspect any Vehicle at Our own expense should it be
                considered necessary by Us to deal with any complaint and You
                are required to allow Our inspector to do so in accordance with
                these terms and conditions. We will provide an unbiased second
                opinion on the initial Report issued.
              </p>
            </li>
          </ol>
          <h2 style={{ marginTop: "1rem" }}>
            Matters outside of Our reasonable control{" "}
          </h2>
          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                We will not be liable for delay to/non-performance of the
                Inspection due to events or circumstances outside Our reasonable
                control. We will take steps to minimise the effects of this
                where possible.
              </p>
            </li>
          </ol>
          <h2 style={{ marginTop: "1rem" }}>
            Exclusion of liability for loss{" "}
          </h2>

          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Wisedrive will only be liable to the Customer (and not to any
                other person) to the extent that Wisedrive fails to carry out
                the Inspection in accordance with these terms and conditions.
                Without limiting the foregoing, You acknowledge that neither
                Wisedrive or the Inspector will be liable to You or any other
                person for any liability, loss, damage, claim, proceedings,
                costs or expenses (together referred to as &ldquo;Losses&rdquo;)
                suffered or incurred by You in circumstances where:
              </p>
            </li>

            <li style={{ listStyleType: "disc" }}>
              <p>
                such Losses are not a reasonably foreseeable result of any such
                breach (Losses are foreseeable where they could be contemplated
                by You and Us at the time the Vehicle is inspected);
              </p>
            </li>

            <li style={{ listStyleType: "disc" }}>
              <p>
                such Losses were not caused directly by a breach by Us or the
                Inspector of these terms and conditions;
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                such Losses comprise business losses or other losses to
                customers who are not consumers; or
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                such Losses increase as a result of a breach by You of any of
                these terms and conditions.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Nothing herein will affect the statutory rights of a consumer
                under any applicable statute.
              </p>
            </li>
          </ol>
          <h2 style={{ marginTop: "1rem" }}>Enforcement of terms </h2>

          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                Each of the clauses of these terms operates separately. If any
                court of relevant authority decides that any of them are
                unlawful, the remaining clauses will remain in full force and
                effect.
              </p>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <p>
                The Agreement is between You and Wisedrive. No other person
                shall have any rights to enforce any of its terms. Neither of us
                will need to get the agreement of any person in order to make
                any changes to these terms.
              </p>
            </li>
          </ol>
          <h2 style={{ marginTop: "1rem" }}>Report advice </h2>
          <ol>
            <li style={{ listStyleType: "disc" }}>
              <p>
                You are strongly advised to discuss the findings within the
                Report with the seller prior to making any decision to purchase
                the Vehicle. If You have not viewed the Vehicle personally, You
                must ensure that the Vehicle&rsquo;s cosmetic appearance and
                specification is satisfactory and suitable for your needs and
                expectations. We suggest You do so by viewing and driving the
                Vehicle yourself, prior to making your purchasing decision.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;
