import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer/index1";
import Faqsection from "../faq/faqSection";

function Faq() {
  return (
    <>
      <Header />
      <Faqsection />
    </>
  );
}

export default Faq;
