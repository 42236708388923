import React, { useEffect, useState } from 'react';
import './PaymentStatus.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Paper,
  TextField,
  Link,
  Chip,
  InputAdornment,
  Divider,
  Typography,
} from '@mui/material';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
// import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from 'react-otp-input';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';
import PaymentForm from '../AffordabilityPayUWidget/AffordabilityWidget';
import { basicAuth, getInspectionPlans } from '../../Api/crmPortal';
import payuIcon from '../../assets/images/buyNowPopup/payu.png';
import EastIcon from '@mui/icons-material/East';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EmailIcon from '@mui/icons-material/Email';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { SendIpOnHomeLoad } from '../../Api/PortalApis';
import { planDetailsArray } from '../../utils/constants';

const StyledTextField = styled(TextField)`
  & label {
    color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: white; // - Set the Input border
    }
    &:hover fieldset {
      border-color: white; // - Set the Input border when parent has :hover
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: white;
    }
  }
`;
const steps = [
  {
    label: 'Data collection',
    description: `Customer Name, Mobile Number and City of inspection … with a button to Verify OTP`,
    btnLabel: 'Get OTP',
  },
  {
    label: 'OTP',
    description: ' ⁠4 boxes for OTP and a butting for confirm OTP',
    btnLabel: 'Confirm OTP',
  },
  {
    label: 'Payment',
    description: `payment process (PG integration)`,
    btnLabel: 'Open Whatsapp',
  },
];

const inspectionList = [
  { isBasic: true, desc: 'Engine Health Check & Diagnostic' },
  { isBasic: true, desc: 'Transmission Assessment' },
  { isBasic: true, desc: 'Electrical System Analysis' },
  { isBasic: true, desc: 'Mechanical Components Review' },
  { isBasic: true, desc: 'Interior Evaluation (With Photos) ' },
  { isBasic: true, desc: 'Exterior Evaluation (With Photos)' },
  { isBasic: false, desc: 'OBD-2 Diagnostic Report' },
  { isBasic: false, desc: 'Engine Control Module Analysis' },
  { isBasic: false, desc: 'Body Control Module Inspection' },
  { isBasic: false, desc: 'Accident History Inspection' },
  { isBasic: false, desc: 'Repair Cost Estimate' },
  { isBasic: false, desc: 'Finance Validity & Clearance Check' },
  { isBasic: false, desc: 'Insurance Validity & Expiry Check' },
  { isBasic: false, desc: 'Traffic Challans Verification' },
  { isBasic: false, desc: 'Vehicle Crime Records Check' },
];

const PaymentStatus = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();

  const queryParams = new URLSearchParams(location.search);
  const transactionMessage = queryParams.get('TransactionMessage');
  const transactionStatus = queryParams.get('TransactionStatus');
  const transactionID = queryParams.get('TransactionID');
  const bankReferenceNumber = queryParams.get('BankReferenceNumber');
  const inspectionCount = queryParams.get('InspectionCount');
  const amountReceived = queryParams.get('AmoutReceived');

  const planName = queryParams.get('InspectionType')
    ? queryParams.get('InspectionType')
    : 'standard';
  console.log('planName', planName, transactionMessage);
  const [activeStep, setActiveStep] = React.useState(0);
  const [inspectionPlanDetails, setInspectionPlanDetails] = useState();
  const [selectedValue, setSelectedValue] = useState(planName);
  const [activePlanDetails, setActivePlanDetails] = useState();
  const [packageData, setPackageData] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [couponLabel, setCouponLabel] = useState();
  console.log('couponCode', couponCode);
  const maxSteps = steps.length;
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [otp, setOtp] = useState('');
  const [validateName, setValidateName] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [validateNumber, setValidateNumber] = useState(true);
  const [numberError, setNumberError] = useState(true);
  const isButtondisabled =
    name?.trim().length > 0 &&
    phone?.trim()?.length > 0 &&
    nameError &&
    numberError &&
    phone?.trim()?.length == 10;

  const handleRetryPayment = () => {
    const queryParams = new URLSearchParams({
      userName: name,
      mobileNo: phone,
    });
    navigate(`/plan-details-buy-now?${queryParams.toString()}`);
  };

  const handleOpenWhatsApp = () => {
    const phoneNumber = '7338073380';
    const message = 'Hi'; // Optional message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the URL in a new tab or window
    window.open(url, '_blank');
  };

  return (
    <section style={{ paddingBottom: '20px' }}>
      <div className="container status-container">
        <div className="payment-status-body">
          <div className={transactionStatus?.toLocaleLowerCase() === 'success'}>
            {transactionStatus?.toLocaleLowerCase() === 'success' ? (
              <div>
                <div className="payment-status-header">
                  <div className="status-icon">
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <CheckCircleIcon
                        sx={{ color: 'green', width: '45px', height: '45px' }}
                      />
                      <h5 style={{ color: 'black' }}>Rs {amountReceived}</h5>
                    </div>
                    <p style={{ paddingTop: '0px', color: 'green' }}>
                      Payment Received
                    </p>
                  </div>
                  <p className="company-ltd">
                    To <strong>Wisedrive Technologies Pvt Ltd</strong>
                  </p>
                </div>
                <Divider
                  style={{
                    marginTop: '1rem',
                    // marginBottom: ,
                    borderColor: 'green',
                    borderWidth: '1px',
                  }}
                />
                <div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'matter',
                      color: 'gray',
                    }}
                  >
                    Transaction Details
                  </p>
                  <div className="transaction-details-container">
                    <div className="payment-status-transaction-details">
                      <p style={{ fontFamily: 'matter' }}>Transaction No: </p>
                      <p>{transactionID}</p>
                    </div>
                    <div className="payment-status-transaction-details">
                      <p style={{ fontFamily: 'matter' }}>Ref No: </p>
                      <p>{bankReferenceNumber}</p>
                    </div>
                    <div className="payment-status-transaction-details">
                      <p style={{ fontFamily: 'matter' }}>Inspection Type: </p>
                      <p>{planName}</p>
                    </div>
                    <div className="payment-status-transaction-details">
                      <p style={{ fontFamily: 'matter' }}>
                        No. of Inspections:{' '}
                      </p>
                      <p>{inspectionCount}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="transaction-communication">
                  <EmailIcon sx={{ color: '#ae1144' }} />
                  <p
                    style={{
                      paddingTop: '0px',
                      color: '#ae1144',
                      lineHeight: '16px',
                    }}
                  >
                    Payment Receipt has been sent to the{' '}
                    <span
                      style={{
                        fontSize: '16px',
                        fontFamily: 'matterBold',
                        color: '#ae1144',
                      }}
                    >
                      customer@email.com
                    </span>
                  </p>
                </div> */}
                <Divider
                  style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    borderColor: 'green',
                    borderWidth: '1px',
                  }}
                />
                <p
                  className="book-inspection-contact"
                  style={{
                    textAlign: 'center',
                    color: 'gray',
                  }}
                >
                  Book Inspection / Contact us
                </p>
                <div>
                  <p
                    className="mobile-note-payment"
                    style={{
                      fontFamily: 'matter',
                      textAlign: 'center',
                      fontSize: '18px',
                      padding: '35px',
                    }}
                  >
                    For booking inspection, say Hi on{' '}
                    <strong>Whatsapp at 73380 73380 </strong> or{' '}
                    <strong>call us on 7353 34 35 36 </strong>
                    for any queries from 9am - 7pm Mon - Sat.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    justifyContent: 'center',
                  }}
                >
                  {/* <Button
                    variant="contained"
                    sx={{
                      width: '50%',
                      backgroundColor: '#ff7858',
                      '&:hover': {
                        backgroundColor: '#ff7858',
                      },
                    }}
                    onClick={() => handleOpenWhatsApp()}
                  >
                    Schedule Inspection
                  </Button> */}
                  <Button
                    variant="contained"
                    className="whatsapp-mobile-payment"
                    sx={{
                      width: '50%',
                      height: '50px',
                      fontFamily: 'matterMedium',
                      fontSize: '1rem',
                      backgroundColor: 'green',
                      '&:hover': {
                        backgroundColor: 'green',
                      },
                    }}
                    startIcon={
                      <WhatsAppIcon
                        sx={{ width: '25px', height: '25px', color: 'white' }}
                      />
                    }
                    onClick={() => handleOpenWhatsApp()}
                  >
                    Open Whatsapp
                  </Button>
                </div>
                {/* <div className="transaction-communication">
                  <SupportAgentRoundedIcon
                    sx={{ height: '30px', width: '30px' }}
                  />
                  <p style={{ lineHeight: '16px' }}>
                    <span
                      style={{
                        fontSize: '16px',
                        fontFamily: 'matterBold',
                      }}
                    >
                      Call us at 7353 34 35 36{' '}
                    </span>{' '}
                    to Book inspection with our agent
                  </p>
                </div> */}
              </div>
            ) : transactionStatus?.toLocaleLowerCase() === 'failure' ? (
              <div>
                <div className="payment-status-header">
                  <div className="status-icon">
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <CancelRoundedIcon
                        sx={{ color: '#aa1945', width: '50px', height: '50px' }}
                      />
                      <h5 style={{ color: 'black' }}>Rs {amountReceived}</h5>
                    </div>
                    <p style={{ paddingTop: '0px', color: 'red' }}>
                      Payment Failed
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: 'matter',
                      color: 'gray',
                      paddingTop: '0px',
                      fontSize: '16px',
                    }}
                  >
                    To <strong>Wisedrive Technologies Pvt Ltd</strong>
                  </p>
                </div>
                <Divider
                  style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    borderColor: '#aa1945',
                  }}
                />
                <div>
                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'matter',
                      color: 'gray',
                    }}
                  >
                    Transaction Details
                  </p>
                  <p>Txn No:{transactionID}</p>
                </div>
                <div style={{ marginTop: '20px' }}>
                  {/* <Button
                    variant="outlined"
                    sx={{
                      height: '50px',
                      width: '100%',
                      backgroundColor: '#DCEEFF',
                      fontFamily: 'matterMedium',
                      fontSize: '18px',
                      boxShadow: 'none',
                      color: '#0C286A',
                      '&:hover': {
                        backgroundColor: '#DCEEFF',
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => handleRetryPayment()}
                  >
                    Retry Payment
                  </Button> */}
                </div>
                <Divider
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '0.5rem',
                    borderColor: '#aa1945',
                  }}
                />
                <p
                  style={{
                    textAlign: 'center',
                    color: 'gray',
                  }}
                >
                  Book Inspection / Contact us
                </p>
                <div>
                  <p
                    style={{
                      fontFamily: 'matter',
                      textAlign: 'center',
                      fontSize: '18px',
                      padding: '35px',
                    }}
                  >
                    For booking inspection, say Hi on{' '}
                    <strong>Whatsapp at 73380 73380 </strong> or{' '}
                    <strong>call us on 7353 34 35 36 </strong>
                    for any queries from 9am - 7pm Mon - Sat.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    justifyContent: 'center',
                  }}
                >
                  {/* <Button
                    variant="contained"
                    sx={{
                      width: '50%',
                      backgroundColor: '#ff7858',
                      '&:hover': {
                        backgroundColor: '#ff7858',
                      },
                    }}
                    onClick={() => handleOpenWhatsApp()}
                  >
                    Schedule Inspection
                  </Button> */}
                  <Button
                    variant="contained"
                    startIcon={
                      <WhatsAppIcon
                        sx={{ width: '25px', height: '25px', color: 'white' }}
                      />
                    }
                    className="whatsapp-mobile-payment"
                    sx={{
                      width: '50%',
                      height: '50px',
                      fontFamily: 'matterMedium',
                      fontSize: '1rem',
                      backgroundColor: 'green',
                      '&:hover': {
                        backgroundColor: 'green',
                      },
                    }}
                    onClick={() => handleOpenWhatsApp()}
                  >
                    Open Whatsapp
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentStatus;
