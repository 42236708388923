import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import './modal.css';
import { styled } from '@mui/material/styles';
import {
  Button,
  InputAdornment,
  TextField,
  Link,
  CircularProgress,
  Divider,
} from '@mui/material';
import CarInspection from '../../assets/images/payment/carInspection.png';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
import axios from 'axios';
import { basicAuth } from '../../Api/crmPortal';
import OtpInput from 'react-otp-input';
import PaymentForm from '../../components/AffordabilityPayUWidget/AffordabilityWidget';
import payuIcon from '../../assets/images/buyNowPopup/payu.png';
import CloseIcon from '@mui/icons-material/Close';

const StyledTextField = styled(TextField)`
  & label {
    color: black;
  }
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: #003cc4; // - Set the Input border
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: #003cc4; // - Set the Input border when parent has :hover
      border-width: 2px;
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: #003cc4;
      border-width: 2px;
    }
  }
`;

const BookInspectionModal = ({
  open,
  handleClose,
  selectedValue,
  packageData,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [disabelOtpBtn, setDisableotpBtn] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponLabel, setCouponLabel] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [loaderOpen, setLoaderOpen] = useState(false);

  const [nameError, setNameError] = useState(false);
  const isButtondisabled =
    name?.trim().length > 0 &&
    phone?.trim()?.length > 0 &&
    nameError &&
    numberError &&
    phone?.trim()?.length == 10;

  const handleNext = () => {
    setLoaderOpen(false);
    setDisableotpBtn(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setActiveStep(0);
    } else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // step 1
  const handleName = (e) => {
    setValidateName(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setName(value);
    }
  };

  const handleNumber = (e) => {
    setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const resetCouponCode = () => {
    setIsDiscountApplied(false);
    setDiscountAmount(0);
    setCouponLabel('');
    setCouponCode('');
    setTotalAmount(packageData?.totalPrice);
  };

  const handleSendOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, name: name };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/sendotptolead`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.status == 200) {
        setDisableotpBtn(false);
        setLoaderOpen(false);
        setOtpSent(true);
      }
    } catch (error) {
      setLoaderOpen(false);
      setDisableotpBtn(false);
      console.log('error', error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, otp: otp };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/validateotp`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.data?.message == 'Invalid otp.') {
        setLoaderOpen(false);
        setInvalidOtp(true);
        setDisableotpBtn(false);
      } else {
        handleNext();
      }
    } catch (error) {
      setLoaderOpen(false);
      console.log('error', error);
    }
  };

  const applyDiscount = async (event) => {
    if (isDiscountApplied) resetCouponCode();
    else {
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/couponcode/isvalid?couponCode=${couponCode}`;
      try {
        setIsDiscountApplied(true);
        const couponResponse = await axios.get(apiUrl, basicAuth);
        let discountAmountCalc = 0;
        if (couponResponse?.data?.discountAmount) {
          discountAmountCalc =
            packageData?.costPrice - couponResponse?.data?.discountAmount;
          discountAmountCalc = Math.round(
            discountAmountCalc + (discountAmountCalc * 18) / 100
          );
          setCouponLabel('Coupon Applied Successfully');
          setDiscountAmount(couponResponse?.data?.discountAmount);
          setTotalAmount(discountAmountCalc);
        } else if (couponResponse?.data?.message) {
          setCouponLabel(couponResponse?.data?.message);
          setDiscountAmount(discountAmountCalc);
          setTotalAmount(packageData?.totalPrice);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  useEffect(() => {
    setTotalAmount(packageData?.totalPrice);
  }, [packageData]);

  console.log('activeStep', activeStep);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box-book-inspection">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon fontSize="small" onClick={handleClose}></CloseIcon>
        </div>
        {loaderOpen && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '80%',
              justifyContent: 'center',
              position: 'absolute',
              width: '90%',
              // background: 'rgba(255, 255, 255, 0.8)' /* Light overlay */,
              zIndex: '999',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {activeStep == 0 && (
          <div>
            <div className="buyNow-header">
              <img src={CarInspection} width="50px" alt="inspection"></img>
              <h6>
                Unlocking Standard
                <br /> Car Inspection
              </h6>
            </div>
            <div>
              <div>
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  {/* <Typography>Name</Typography> */}
                  <StyledTextField
                    id="standard-basic"
                    variant="outlined"
                    value={name}
                    onChange={handleName}
                    label="Name"
                    sx={{
                      width: '100% !important',
                      borderRadius: '10px',
                      '&:hover': {
                        borderColor: 'black !important',
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: 'black',
                        // height: '10px !important',
                        fontFamily: 'matter !important',
                      },
                    }}
                  />
                  {validateName && (
                    <p style={{ color: 'red' }}>Please enter name</p>
                  )}
                </div>
                <div
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    flexDirection: 'column',
                  }}
                >
                  <StyledTextField
                    id="standard-basic"
                    variant="outlined"
                    label="Mobile Number"
                    value={phone}
                    onChange={handleNumber}
                    endAdornment={<button>Test</button>}
                    sx={{
                      width: '100% !important',
                      borderRadius: '10px',
                    }}
                    InputProps={{
                      sx: {
                        color: 'black',
                        // height: '60px !important',
                        fontFamily: 'matter !important',
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            sx={{
                              // marginTop: '15px',
                              width: '100%',
                              backgroundColor: '#ff7858',
                              borderRadius: 1,
                              color: 'white',
                              height: '30px',
                              '&:hover': {
                                backgroundColor: '#ff7858 !important',
                                color: 'white',
                              },
                            }}
                            disabled={!name || !phone || disabelOtpBtn}
                            onClick={() => handleSendOtp()}
                          >
                            {otpSent ? 'Resend Otp' : 'Send Otp'}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validateNumber && (
                    <p style={{ color: 'red' }}>Please enter number</p>
                  )}
                  {numberError && (
                    <p style={{ color: 'red' }}>Number is less than 10 digit</p>
                  )}
                  {otpSent && (
                    <p
                      style={{
                        fontFamily: 'matterThin',
                        fontSize: '14px',
                      }}
                    >
                      OTP sent via SMS to your mobile number
                    </p>
                  )}
                </div>
                <div className="buyNow-otp">
                  <div style={{ marginTop: '1rem' }}>
                    <p>Enter OTP</p>
                  </div>
                  <div
                    className="otp-input-container"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <OtpInput
                      className="otp-input"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e);
                      }}
                      numInputs={6}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  {invalidOtp && (
                    <p
                      style={{
                        fontFamily: 'matterThin',
                        fontSize: '14px',
                      }}
                    >
                      Please Enter Valid OTP
                    </p>
                  )}
                </div>
                <Button
                  style={{ marginTop: '1rem' }}
                  onClick={() => handleVerifyOtp()}
                  sx={{
                    width: '100%',
                    height: '55px',
                    fontSize: '20px',
                    backgroundColor: '#003cc4',
                    color: 'white',
                    borderRadius: 1,
                    fontFamily: 'matterSemiBold',
                    '&:hover': {
                      backgroundColor: '#003cc4 !important',
                      color: 'white',
                    },
                  }}
                  disabled={otp?.length !== 6}
                >
                  Verify OTP
                </Button>
                <p
                  style={{
                    paddingTop: '5px',
                    fontFamily: 'matterThin',
                    fontSize: '12px',
                    textAlign: 'center',
                  }}
                >
                  By contacting us, you agree to our{' '}
                  <strong>
                    Terms <br />
                    of service
                  </strong>{' '}
                  and <strong>Privacy Policy</strong>
                </p>
              </div>
            </div>
          </div>
        )}
        {activeStep == 1 && (
          <>
            <div
              className="buy-now-payment-table-content"
              style={{ width: '100%', padding: '10px' }}
            >
              <div className="div-invoice" style={{ marginTop: '0px' }}>
                <p style={{ fontSize: '22px' }}>Summary</p>
                <p style={{ fontFamily: 'matter', color: 'grey' }}>
                  {selectedValue == 'Standard'
                    ? 'Standard Plan'
                    : 'Luxury Plan'}
                </p>
              </div>
              <div className="div-invoice" style={{ marginTop: '0.5rem' }}>
                <p>
                  Package Amount <br />
                  {`(${
                    packageData?.inspectionCount
                  } Inspection x ₹ ${Math.round(
                    packageData?.costPrice / packageData?.inspectionCount
                  )})`}
                </p>
                <p style={{ fontSize: '18px' }}>{packageData?.costPrice}</p>
              </div>
              <div className="div-promocode">
                <TextField
                  id="standard-basic"
                  variant="outlined"
                  label="Coupon Code"
                  fullWidth
                  onChange={(event) => {
                    setCouponCode(event.target.value);
                  }}
                  value={couponCode}
                  style={{ height: '40px !important' }}
                  sx={{
                    height: '40px !important',
                    alignItems: 'baseline',
                    borderBottomColor: 'white',
                    textTransform: 'capitalize !important',
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'white',
                    },
                    '& .MuiInput-underline': {
                      borderBottomColor: 'white',
                    },
                    '&.MuiInputBase-root-MuiOutlinedInput-root': {
                      fontFamily: 'matter',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link
                          onClick={() => applyDiscount(couponCode)}
                          style={{
                            // marginBottom: "20px",
                            color: '#eb6d47',
                            textDecoration: 'none',
                            fontFamily: 'matterBold',
                          }}
                        >
                          {isDiscountApplied ? 'Change' : 'Apply'}
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                  className="txt-promoCoe"
                />
              </div>
              <div>
                {couponLabel && <p style={{ color: 'red' }}>{couponLabel}</p>}
              </div>

              <div className="div-invoice" style={{ paddingTop: '10px' }}>
                <p>Discount Applied </p>
                <p style={{ color: discountAmount > 0 && 'red' }}>
                  {discountAmount > 0 && '-'}₹ {discountAmount}
                </p>
              </div>
              <div className="div-invoice">
                <p>Tax ({packageData?.taxPercent}% GST) </p>
                <p>
                  ₹
                  {/* {Math.round(
                        ((packageData?.costPrice - discountAmount) *
                          packageData?.taxPercent) /
                          100
                      )} */}
                  {((packageData?.costPrice - discountAmount) *
                    packageData?.taxPercent) /
                    100}
                </p>
              </div>

              <Divider sx={{ marginTop: 2 }} />
              <div className="div-invoice" style={{ paddingTop: '10px' }}>
                <h4>Total Amount (INR)</h4>
                <p
                  style={{
                    fontSize: '25px',
                    fontFamily: 'matterSemiBold',
                  }}
                >
                  ₹ {totalAmount}
                </p>
              </div>
              {/* <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Your Name"
                      value={name}
                      required
                      sx={{
                        fontFamily: 'matter',
                      }}
                      error={!validateName}
                      helperText={
                        !validateName
                          ? 'Please enter only text (letters and spaces).'
                          : ''
                      }
                      className="txt-promoCoe"
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => handleName(e)}
                    />
                  </div>
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Mobile Number"
                      required
                      value={phone}
                      sx={{
                        fontFamily: 'matter',
                      }}
                      error={!validateNumber}
                      helperText={
                        !validateNumber ? 'Please enter Valid Mobile No' : ''
                      }
                      className="txt-promoCoe"
                      onChange={(e) => handleNumber(e)}
                    />
                  </div> */}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <PaymentForm
                  userData={{
                    userName: name,
                    nameError: nameError,
                    couponCode: couponCode,
                    Mobile: phone,
                    numberError: numberError,
                  }}
                  isButtondisabled={isButtondisabled}
                  packageDetails={{
                    amount: totalAmount,
                    productinfo: 'Inspection',
                    phone: phone,
                    firstName: name,
                    packageInstanceId: packageData?.packageInstanceId,
                    couponCode: couponCode,
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '1.5rem',
                  justifyContent: 'space-around',
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontSize: '12px',
                  }}
                >
                  By Proceeding, I understand and agree with the{' '}
                  <span style={{ fontFamily: 'matterSemiBold' }}>
                    Terms of Service
                  </span>{' '}
                  and{' '}
                  <span style={{ fontFamily: 'matterSemiBold' }}>
                    Privacy Policy{' '}
                  </span>
                  of Wisedrive Technologies Pvt Ltd
                </p>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    marginBottom: '1.5rem',
                  }}
                >
                  <p>Payments secured by</p>
                  <img src={payuIcon} width="80px" height="40px" alt="" />
                </div>
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};
export default BookInspectionModal;
