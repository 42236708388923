import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Banner from './banner';
import './report-not-found.css'
import Recommendations from './recommendations';

const ReportNotAvailable = () => {
  return (
   <>
        <Header />
        <Banner />
        <Recommendations />
        <Footer />
   </>
  )
}

export default ReportNotAvailable