export const getItem = async (key) => {
    const item = await localStorage.getItem(key);
    return item;
  };
  
  export const setItem = async (key, value) => {
    await localStorage.setItem(key, value);
    return getItem(key);
  };
  
  export const deleteItem = async (key) => {
    await localStorage.removeItem(key);
    return getItem(key) === "";
  };
  
  export const clearAllItem = async () => {
    await localStorage.clear();
  };
  