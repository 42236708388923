import React, { useEffect, useState } from 'react';
import './BuyNow.css';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Paper,
  TextField,
  InputAdornment,
  Divider,
  Link,
  Modal,
  CircularProgress,
} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CarInspection from '../../assets/images/payment/carInspection.png';
import Hyderabad from '../../assets/images/cities/hyderabad.png';
import Mumbai from '../../assets/images/cities/mumbai.png';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
import OtpInput from 'react-otp-input';
import { styled } from '@mui/material/styles';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import axios from 'axios';
import { basicAuth } from '../../Api/crmPortal';
import PaymentForm from '../../components/AffordabilityPayUWidget/AffordabilityWidget';
import payuIcon from '../../assets/images/buyNowPopup/payu.png';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BuyNowModal from '../../components/popups/buyNowModal';

const StyledTextField = styled(TextField)`
  & label {
    color: black;
  }
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: #003cc4; // - Set the Input border
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: #003cc4; // - Set the Input border when parent has :hover
      border-width: 2px;
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: #003cc4;
      border-width: 2px;
    }
  }
`;

const BuyNowComponent = ({
  selectedValue,
  packageData,
  setStepperVisible,
  inspectionCount,
  setPageDisable,
}) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [disabelOtpBtn, setDisableotpBtn] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);

  const [date, setDate] = useState(dayjs(new Date()));
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedTime, setSelectedTime] = useState(dayjs(new Date()));

  const [couponCode, setCouponCode] = useState('');
  const [couponLabel, setCouponLabel] = useState();
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [nameError, setNameError] = useState(false);
  const isButtondisabled =
    name?.trim().length > 0 &&
    phone?.trim()?.length > 0 &&
    nameError &&
    numberError &&
    phone?.trim()?.length == 10;

  const queryParams = new URLSearchParams(location.search);

  const transactionMessage = queryParams.get('TransactionMessage');
  const transactionStatus = queryParams.get('TransactionStatus');
  const transactionID = queryParams.get('TransactionID');
  const bankReferenceNumber = queryParams.get('BankReferenceNumber');
  const inspectionCountRes = queryParams.get('InspectionCount');
  const amountReceived = queryParams.get('AmoutReceived');
  const planName = queryParams.get('InspectionType')
    ? queryParams.get('InspectionType')
    : 'Standard';

  const handleSendOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, name: name };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/sendotptolead`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.status == 200) {
        setLoaderOpen(false);
        setDisableotpBtn(false);
        setOtpSent(true);
      }
    } catch (error) {
      setLoaderOpen(false);
      setDisableotpBtn(false);
      console.log('error', error);
    }
  };
  const handleRetryPayment = () => {
    const queryParams = new URLSearchParams({
      userName: name,
      mobileNo: phone,
    });
    navigate(`/plan-details-buy-now?${queryParams.toString()}`);
  };

  const applyDiscount = async (event) => {
    if (isDiscountApplied) resetCouponCode();
    else {
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/couponcode/isvalid?couponCode=${couponCode}`;
      try {
        setIsDiscountApplied(true);
        const couponResponse = await axios.get(apiUrl, basicAuth);
        let discountAmountCalc = 0;
        if (couponResponse?.data?.discountAmount) {
          discountAmountCalc =
            packageData?.costPrice - couponResponse?.data?.discountAmount;
          discountAmountCalc = Math.round(
            discountAmountCalc + (discountAmountCalc * 18) / 100
          );
          setCouponLabel('Coupon Applied Successfully');
          setDiscountAmount(couponResponse?.data?.discountAmount);
          setTotalAmount(discountAmountCalc);
        } else if (couponResponse?.data?.message) {
          setCouponLabel(couponResponse?.data?.message);
          setDiscountAmount(discountAmountCalc);
          setTotalAmount(packageData?.totalPrice);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const resetCouponCode = () => {
    setIsDiscountApplied(false);
    setDiscountAmount(0);
    setCouponLabel('');
    setCouponCode('');
    setTotalAmount(packageData?.totalPrice);
  };

  const handleNext = () => {
    setLoaderOpen(false);
    setDisableotpBtn(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // setPageDisable();
    if (activeStep == 0) {
      setStepperVisible();
      setActiveStep(0);
    } else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // step 1
  const handleName = (e) => {
    setValidateName(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setName(value);
    }
  };

  const handleNumber = (e) => {
    setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const getOtp = async (event) => {
    alert();
    event.preventDefault();
  };

  const handleVerifyOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, otp: otp };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/validateotp`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.data?.message == 'Invalid otp.') {
        setLoaderOpen(false);
        setInvalidOtp(true);
        setDisableotpBtn(false);
      } else {
        handleNext();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setTotalAmount(packageData?.totalPrice);
  }, [packageData]);

  return (
    <>
      {loaderOpen && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '80%',
            justifyContent: 'center',
            position: 'absolute',
            width: '55%',
            background: 'rgba(255, 255, 255, 0.8)' /* Light overlay */,
            zIndex: '999',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="buynow-container">
        <div className="buyNow-Container-left">
          <Divider orientation="vertical" />
          <div>
            <ArrowCircleLeftIcon onClick={() => handleBack()} />
          </div>
        </div>
        <div style={{ paddingTop: '40px' }}>
          <div className="buyNow-content">
            <Paper
              sx={{
                width: '100%',
                backgroundColor: '#white',
                boxShadow: 'none !important',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {transactionStatus ? (
                <div
                  className={
                    transactionStatus?.toLocaleLowerCase() === 'success'
                      ? 'buynow-payment-container-success'
                      : transactionStatus === 'failure'
                      ? 'buynow-payment-container-failure'
                      : 'buynow-payment-container'
                  }
                >
                  {transactionStatus?.toLocaleLowerCase() === 'success' ? (
                    <div className="buy-now-payment-table-content">
                      <div className="transaction-success-header-div">
                        <CheckCircleIcon
                          sx={{ color: 'green', width: '50px', height: '50px' }}
                        />
                        <p>paid</p>
                        <h5>Rs {amountReceived}</h5>
                        <p>To Wisedrive Technologies Pvt Ltd</p>
                      </div>
                      <Divider
                        style={{
                          marginTop: '0.5rem',
                          marginBottom: '0.5rem',
                          borderColor: 'green',
                        }}
                      />
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          Transaction Details
                        </p>
                        <p>Transaction No: {transactionID}</p>
                        <p>Ref No: {bankReferenceNumber}</p>
                        <p>Inspection Type: {planName}</p>
                        <p>No. of Inspections: {inspectionCountRes}</p>
                      </div>
                      <div className="transaction-communication">
                        <EmailIcon sx={{ color: '#ae1144' }} />
                        <p
                          style={{
                            paddingTop: '0px',
                            color: '#ae1144',
                            lineHeight: '16px',
                          }}
                        >
                          Payment Receipt has been sent to the{' '}
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'matterBold',
                              color: '#ae1144',
                            }}
                          >
                            customer@email.com
                          </span>
                        </p>
                      </div>
                      <Divider
                        style={{
                          marginTop: '0.5rem',
                          marginBottom: '0.5rem',
                          borderColor: 'green',
                        }}
                      />
                      <p style={{ textAlign: 'center' }}>
                        Book Inspection / Contact us
                      </p>
                      <div className="transaction-communication">
                        <WhatsAppIcon
                          sx={{ color: 'green', height: '30px', width: '30px' }}
                        />
                        <p style={{ lineHeight: '16px' }}>
                          Say hi on{' '}
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'matterBold',
                            }}
                          >
                            Whatsapp at 73380 73380{' '}
                          </span>{' '}
                          for any queries from 9am - 7pm Mon - Sat.
                        </p>
                      </div>
                      <div className="transaction-communication">
                        <SupportAgentRoundedIcon
                          sx={{ height: '30px', width: '30px' }}
                        />
                        <p style={{ lineHeight: '16px' }}>
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'matterBold',
                            }}
                          >
                            Call us at 7353 34 35 36{' '}
                          </span>{' '}
                          to Book inspection with our agent
                        </p>
                      </div>
                    </div>
                  ) : transactionStatus?.toLocaleLowerCase() === 'failure' ? (
                    <div className="buy-now-payment-table-content">
                      <div className="transaction-success-header-div">
                        <CancelRoundedIcon
                          sx={{
                            color: '#aa1945',
                            width: '50px',
                            height: '50px',
                          }}
                        />
                        <p>Payment Failed</p>
                        <h5>Rs {amountReceived}</h5>
                        <p>To Wisedrive Technologies Pvt Ltd</p>
                      </div>
                      <Divider
                        style={{
                          marginTop: '0.5rem',
                          marginBottom: '0.5rem',
                          borderColor: '#aa1945',
                        }}
                      />
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          Transaction Details
                        </p>
                        <p>Txn No:{transactionID}</p>
                      </div>

                      <Button
                        variant="outlined"
                        sx={{
                          height: '50px',
                          width: '100%',
                          backgroundColor: '#DCEEFF',
                          fontFamily: 'matterMedium',
                          fontSize: '18px',
                          boxShadow: 'none',
                          color: '#0C286A',
                          '&:hover': {
                            backgroundColor: '#DCEEFF',
                            boxShadow: 'none',
                          },
                        }}
                        onClick={() => handleRetryPayment()}
                      >
                        Retry Payment
                      </Button>
                      <Divider
                        style={{
                          marginTop: '1.5rem',
                          marginBottom: '0.5rem',
                          borderColor: '#aa1945',
                        }}
                      />
                      <p style={{ textAlign: 'center' }}>
                        Book Inspection / Contact us
                      </p>
                      <div className="transaction-communication">
                        <WhatsAppIcon
                          sx={{ color: 'green', height: '30px', width: '30px' }}
                        />
                        <p style={{ lineHeight: '16px' }}>
                          Say hi on{' '}
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'matterBold',
                            }}
                          >
                            Whatsapp at 73380 73380{' '}
                          </span>{' '}
                          for any queries from 9am - 7pm Mon - Sat.
                        </p>
                      </div>
                      <div className="transaction-communication">
                        <SupportAgentRoundedIcon
                          sx={{ height: '30px', width: '30px' }}
                        />
                        <p style={{ lineHeight: '16px' }}>
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'matterBold',
                            }}
                          >
                            Call us at 7353 34 35 36{' '}
                          </span>{' '}
                          to Book inspection with our agent
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <>
                  {' '}
                  {activeStep === 0 && (
                    <>
                      <div className="buyNow-header">
                        <img
                          src={CarInspection}
                          width="50px"
                          alt="inspection"
                        ></img>
                        <h6>
                          Unlocking Standard
                          <br /> Car Inspection
                        </h6>
                      </div>
                      <div className="buyNow-paper">
                        <div>
                          <div
                            style={{
                              marginTop: '20px',
                              marginBottom: '10px',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}
                          >
                            {/* <Typography>Name</Typography> */}
                            <StyledTextField
                              id="standard-basic"
                              variant="outlined"
                              value={name}
                              onChange={handleName}
                              label="Name"
                              sx={{
                                width: '100% !important',
                                borderRadius: '10px',
                                '&:hover': {
                                  borderColor: 'black !important',
                                },
                              }}
                              inputProps={{
                                sx: {
                                  color: 'black',
                                  // height: '10px !important',
                                  fontFamily: 'matter !important',
                                },
                              }}
                            />
                            {validateName && (
                              <p style={{ color: 'red' }}>Please enter name</p>
                            )}
                          </div>
                          <div
                            style={{
                              width: '100%',
                              marginBottom: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              flexDirection: 'column',
                            }}
                          >
                            <StyledTextField
                              id="standard-basic"
                              variant="outlined"
                              label="Mobile Number"
                              value={phone}
                              onChange={handleNumber}
                              endAdornment={<button>Test</button>}
                              sx={{
                                width: '100% !important',
                                borderRadius: '10px',
                              }}
                              InputProps={{
                                sx: {
                                  color: 'black',
                                  // height: '60px !important',
                                  fontFamily: 'matter !important',
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Button
                                      sx={{
                                        // marginTop: '15px',
                                        width: '100%',
                                        backgroundColor: '#ff7858',
                                        borderRadius: 1,
                                        color: 'white',
                                        height: '30px',
                                        '&:hover': {
                                          backgroundColor: '#ff7858 !important',
                                          color: 'white',
                                        },
                                      }}
                                      disabled={
                                        !name || !phone || disabelOtpBtn
                                      }
                                      onClick={() => handleSendOtp()}
                                    >
                                      {otpSent ? 'Resend Otp' : 'Send Otp'}
                                    </Button>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {validateNumber && (
                              <p style={{ color: 'red' }}>
                                Please enter number
                              </p>
                            )}
                            {numberError && (
                              <p style={{ color: 'red' }}>
                                Number is less than 10 digit
                              </p>
                            )}
                            {otpSent && (
                              <p
                                style={{
                                  fontFamily: 'matterThin',
                                  fontSize: '14px',
                                }}
                              >
                                OTP sent via SMS to your mobile number
                              </p>
                            )}
                          </div>
                          <div className="buyNow-otp">
                            <div style={{ marginTop: '2.5rem' }}>
                              <p>Enter OTP</p>
                            </div>
                            <div
                              className="otp-input-container"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <OtpInput
                                className="otp-input"
                                value={otp}
                                onChange={(e) => {
                                  setOtp(e);
                                }}
                                numInputs={6}
                                // renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            {invalidOtp && (
                              <p
                                style={{
                                  fontFamily: 'matterThin',
                                  fontSize: '14px',
                                }}
                              >
                                Please Enter Valid OTP
                              </p>
                            )}
                          </div>
                          <Button
                            style={{ marginTop: '1rem' }}
                            onClick={() => handleVerifyOtp()}
                            sx={{
                              width: '100%',
                              height: '55px',
                              fontSize: '20px',
                              backgroundColor: '#003cc4',
                              color: 'white',
                              borderRadius: 1,
                              fontFamily: 'matterSemiBold',
                              '&:hover': {
                                backgroundColor: '#003cc4 !important',
                                color: 'white',
                              },
                            }}
                            disabled={otp?.length !== 6}
                          >
                            Verify OTP
                          </Button>
                          <p
                            style={{
                              paddingTop: '5px',
                              fontFamily: 'matterThin',
                              fontSize: '12px',
                              textAlign: 'center',
                            }}
                          >
                            By contacting us, you agree to our{' '}
                            <strong>
                              Terms <br />
                              of service
                            </strong>{' '}
                            and <strong>Privacy Policy</strong>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === 1 && (
                    <div>
                      {/* <div className="city-container">
                  <div>
                    <img src={Hyderabad} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Hyderabad
                    </p>
                  </div>
                  <div>
                    <img src={Mumbai} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Mumbai
                    </p>
                  </div>
                  <div>
                    <img src={Hyderabad} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Hyderabad
                    </p>
                  </div>
                  <div>
                    <img src={Mumbai} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Mumbai
                    </p>
                  </div>
                  <div>
                    <img src={Hyderabad} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Hyderabad
                    </p>
                  </div>
                  <div>
                    <img src={Mumbai} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Mumbai
                    </p>
                  </div>
                  <div>
                    <img src={Hyderabad} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Hyderabad
                    </p>
                  </div>
                  <div>
                    <img src={Mumbai} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Mumbai
                    </p>
                  </div>
                  <div>
                    <img src={Hyderabad} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Hyderabad
                    </p>
                  </div>
                  <div>
                    <img src={Mumbai} />
                    <p style={{ fontFamily: 'matter', fontSize: '12px' }}>
                      Mumbai
                    </p>
                  </div>
                </div>
                <div
                  style={{ marginTop: '20px', display: 'flex', gap: '20px' }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Date"
                      value={selectedDate}
                      onChange={(newValue) => setSelectedDate(newValue)}
                    />
                    <TimeField
                      label="Select Time"
                      value={selectedTime}
                      onChange={(newValue) => setSelectedTime(newValue)}
                    />
                  </LocalizationProvider>
                </div> */}
                      <div
                        className="buy-now-payment-table-content"
                        style={{ width: '90%' }}
                      >
                        <div
                          className="div-invoice"
                          style={{ marginTop: '0px' }}
                        >
                          <p style={{ fontSize: '22px' }}>Summary</p>
                          <p style={{ fontFamily: 'matter', color: 'grey' }}>
                            {selectedValue == 'Standard'
                              ? 'Standard Plan'
                              : 'Luxury Plan'}
                          </p>
                        </div>
                        <div
                          className="div-invoice"
                          style={{ marginTop: '0.5rem' }}
                        >
                          <p>
                            Package Amount <br />
                            {`(${
                              packageData?.inspectionCount
                            } Inspection x ₹ ${Math.round(
                              packageData?.costPrice /
                                packageData?.inspectionCount
                            )})`}
                          </p>
                          <p style={{ fontSize: '18px' }}>
                            {packageData?.costPrice}
                          </p>
                        </div>
                        <div className="div-promocode">
                          <TextField
                            id="standard-basic"
                            variant="outlined"
                            label="Coupon Code"
                            fullWidth
                            onChange={(event) => {
                              setCouponCode(event.target.value);
                            }}
                            value={couponCode}
                            style={{ height: '40px !important' }}
                            sx={{
                              height: '40px !important',
                              alignItems: 'baseline',
                              borderBottomColor: 'white',
                              textTransform: 'capitalize !important',
                              '& .MuiInput-underline:after': {
                                borderBottomColor: 'white',
                              },
                              '& .MuiInput-underline': {
                                borderBottomColor: 'white',
                              },
                              '&.MuiInputBase-root-MuiOutlinedInput-root': {
                                fontFamily: 'matter',
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Link
                                    onClick={() => applyDiscount(couponCode)}
                                    style={{
                                      // marginBottom: "20px",
                                      color: '#eb6d47',
                                      textDecoration: 'none',
                                      fontFamily: 'matterBold',
                                    }}
                                  >
                                    {isDiscountApplied ? 'Change' : 'Apply'}
                                  </Link>
                                </InputAdornment>
                              ),
                            }}
                            className="txt-promoCoe"
                          />
                        </div>
                        <div>
                          {couponLabel && (
                            <p style={{ color: 'red' }}>{couponLabel}</p>
                          )}
                        </div>

                        <div
                          className="div-invoice"
                          style={{ paddingTop: '10px' }}
                        >
                          <p>Discount Applied </p>
                          <p style={{ color: discountAmount > 0 && 'red' }}>
                            {discountAmount > 0 && '-'}₹ {discountAmount}
                          </p>
                        </div>
                        <div className="div-invoice">
                          <p>Tax ({packageData?.taxPercent}% GST) </p>
                          <p>
                            ₹
                            {/* {Math.round(
                        ((packageData?.costPrice - discountAmount) *
                          packageData?.taxPercent) /
                          100
                      )} */}
                            {((packageData?.costPrice - discountAmount) *
                              packageData?.taxPercent) /
                              100}
                          </p>
                        </div>
                        <div className="div-invoice seperator desktop">
                          <>&nbsp;</>
                        </div>
                        <div
                          className="div-invoice"
                          style={{ paddingTop: '10px' }}
                        >
                          <h4>Total Amount (INR)</h4>
                          <p
                            style={{
                              fontSize: '25px',
                              fontFamily: 'matterSemiBold',
                            }}
                          >
                            ₹ {totalAmount}
                          </p>
                        </div>
                        {/* <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Your Name"
                      value={name}
                      required
                      sx={{
                        fontFamily: 'matter',
                      }}
                      error={!validateName}
                      helperText={
                        !validateName
                          ? 'Please enter only text (letters and spaces).'
                          : ''
                      }
                      className="txt-promoCoe"
                      inputProps={{ maxLength: 20 }}
                      onChange={(e) => handleName(e)}
                    />
                  </div>
                  <div className="div-promocode">
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label="Mobile Number"
                      required
                      value={phone}
                      sx={{
                        fontFamily: 'matter',
                      }}
                      error={!validateNumber}
                      helperText={
                        !validateNumber ? 'Please enter Valid Mobile No' : ''
                      }
                      className="txt-promoCoe"
                      onChange={(e) => handleNumber(e)}
                    />
                  </div> */}

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <PaymentForm
                            userData={{
                              userName: name,
                              nameError: nameError,
                              couponCode: couponCode,
                              Mobile: phone,
                              numberError: numberError,
                            }}
                            isButtondisabled={isButtondisabled}
                            setPageDisable={setPageDisable}
                            packageDetails={{
                              amount: totalAmount,
                              productinfo: 'Inspection',
                              phone: phone,
                              firstName: name,
                              packageInstanceId: packageData?.packageInstanceId,
                              couponCode: couponCode,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '1.5rem',
                            justifyContent: 'space-around',
                          }}
                        >
                          <p
                            style={{ textAlign: 'center', lineHeight: '20px' }}
                          >
                            By Proceeding, I understand and agree with the{' '}
                            <span style={{ fontFamily: 'matterSemiBold' }}>
                              Terms of Service
                            </span>{' '}
                            and{' '}
                            <span style={{ fontFamily: 'matterSemiBold' }}>
                              Privacy Policy{' '}
                            </span>
                            of Wisedrive Technologies Pvt Ltd
                          </p>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              marginTop: '1.5rem',
                              marginBottom: '1.5rem',
                            }}
                          >
                            <p>Payments secured by</p>
                            <img
                              src={payuIcon}
                              width="80px"
                              height="40px"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="city-btn-container">
                  <div className="city-btn-paynow ">
                    <Button
                      sx={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: '#003cc4',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontFamily: 'matterMedium',
                      }}
                      variant="contained"
                    >
                      Pay Rs 500 Advance
                    </Button>
                    <p
                      style={{
                        paddingTop: '5px',
                        fontFamily: 'matterThin',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Balance amount to be paid post completion <br />
                      of your car inspection
                    </p>
                  </div>
                  <div>
                    <Button
                      sx={{
                        width: '100%',
                        height: '40px',
                        // fontSize: '20px',
                        // backgroundColor: '#003cc4',
                        borderRadius: 1,
                        textTransform: 'none',
                        borderStyle: 'dashed',
                        fontFamily: 'matterMedium',
                      }}
                      variant="outlined"
                    >
                      Pay Full Amount
                    </Button>
                    <p
                      style={{
                        paddingTop: '5px',
                        fontFamily: 'matterThin',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      By contacting us, you agree to our{' '}
                      <strong>
                        Terms <br />
                        of service
                      </strong>{' '}
                      and <strong>Privacy Policy</strong>
                    </p>
                  </div>
                </div> */}
                    </div>
                  )}
                </>
              )}
            </Paper>
          </div>
          <BuyNowModal
            open={invalidOtp}
            handleClose={() => setInvalidOtp(false)}
            handleApprove={() => setInvalidOtp(false)}
            message={'Please enter Valid OTP'}
          />
        </div>
      </div>
    </>
  );
};

export default BuyNowComponent;
