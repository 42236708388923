import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer";
import Header from "../../components/header";
import WarrantyPolicy from "./warranty-policy";
import WarrantyPolicyNew from "./warranty-policy-new";

function index() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.wisedrive.in/warrantyPolicy" />
      </Helmet>
      <Header />
      {/* <WarrantyPolicy /> */}
      <WarrantyPolicyNew />
      <Footer />
    </div>
  );
}

export default index;
