export const vechileInfoData = [
  { label: "VIN", value: "Wdd2183752a171575" },
  { label: "Registration no.", value: "Yqh43j" },
  { label: "Body Type", value: "sedan" },
  { label: "Transmission", value: "automatic" },
  { label: "Exterior Colour", value: "Black" },
  { label: "Odometer Reading", value: "83416" },
  { label: "Mechanic", value: "Luke" },
];

export const vechileRatingGuideData = [
  {
    label: "Excellent",
    value: "Brand new/ recently replaced/ no evidence of fault detected",
  },
  {
    label: "Good",
    value:
      "Item found to be in working order with some signs of damage and/or deterioration, consistent with the age & kilometers of vehicle",
  },
  {
    label: "Fair",
    value:
      "Item found to be in working order with notable signs of damage and/or deterioration, consistent with the age & kilometers of vehicle",
  },
  {
    label: "Poor",
    value:
      "Item found to be in bad condition, needs urgent attention or replacement",
  },
  {
    label: "N/A",
    value: "Item not fitted to the vehicle or able to be checked",
  },
];

export const vechilReportDataSample = [
  { header: "Engine", value: "79" },
  { header: "Interior", value: "74" },
  { header: "Exterior", value: "79" },
  { header: "Wheels", value: "69" },
  { header: "Body Condition", value: "75" },
  { header: "Track Driving", value: "94" },
  { header: "Road Test", value: "83" },
];

export const vechilReportData = [
  {
    header: "Engine",
    Value: 79,
    Description: [
      {
        Label: "Engine bay presentation",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Running noise",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Oil level",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Coolant level",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine oil leaks",
        Rating: "NA",
        Comments: "Can’t see any oil leaks in engine bay or under vehicle.",
        Image: false,
      },
      {
        Label: "Coolant leaks",
        Rating: "NA",
        Comments: "Can’t see any coolant leaks in engine bay or under vehicle.",
        Image: false,
      },
      {
        Label: "Odometre Reading",
        Rating: "YES",
        Comments: "",
        Image: true,
      },
      {
        Label: "Washer fluid level",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Brake fluid level",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Fluid leaks",
        Rating: "No",
        Comments: "",
        Image: false,
      },
      {
        Label: "Mountings",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Hoses & Pipes",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Water pump/ Fan",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Ignition System/Distributor",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Fuel System",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Battery Voltage",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Battery terminal condition",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Radiator & Cap",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Drive belt & pulleys",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Brake Booster",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Master Cylinder/ABS",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Faults detected during diagnostic scan",
        Rating: "NO",
        Comments: "",
        Image: true,
      },
    ],
  },
  {
    header: "Interior",
    Value: 74,
    Description: [
      {
        Label: "Owners manual/keys",
        Rating: "YES",
        Comments: "",
        Image: true,
      },
      {
        Label: "Dah board condition",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Seats",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Seat belts",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Floor mat condition",
        Rating: "Good",
        Comments: "Front floor mats have more wear than the rears.",
        Image: false,
      },
      {
        Label: "Boot condition",
        Rating: "Poor",
        Comments: "Still has belonging in boot. And in a dirty state.",
        Image: true,
      },
      {
        Label: "Trims",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Door trims",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Radio",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Speaker operation",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Rear window demister",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Air Conditioning",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Heater demister",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Washers & Wipers",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Horn working",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Doors, locks & hinges",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Window operation",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Warning lights",
        Rating: "YES",
        Comments:
          "Only has the low tyre pressure light for back left tyre customer is pumping it back up now. But may need further diagnosis.",
        Image: true,
      },
      {
        Label: "All interior lights working",
        Rating: "YES",
        Comments: "",
        Image: false,
      },
      {
        Label: "Gear level condition",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
    ],
  },
  {
    header: "Exterior",
    Value: 79,
    Description: [
      {
        Label: "Rust",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Windscreen",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Windscreen Wiper Rubbers",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Evidence of Damage",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Glass/mirrors",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Sunroof/Convertible operation",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Visible frames & members",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Front suspension",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Rear suspension",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Steering Components",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Exhaust",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Differential",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Drive Shafts",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Uderbody",
        Rating: "YES",
        Comments: "",
        Image: false,
      },
      {
        Label: "All exterior lights working",
        Rating: "YES",
        Comments: "",
        Image: false,
      },
      {
        Label: "Compliance plate",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Build plate",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "General condition aside from cleanliness",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
    ],
  },
  {
    header: "Wheels",
    Value: 9,
    Description: [
      {
        Label: "Spare tyre/rim/tool kit",
        Rating: "YES",
        Comments: "Space saver rim. Tools in boot also.",
        Image: true,
      },
      {
        Label: "Brake hoses / callipers",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Front brakes",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Rear brakes",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Park brake",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "RHR tyre condition",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHF rim condition",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHF tyre condition",
        Rating: "Fair",
        Comments: "Outter edge is slightly wearing. May need wheel alignment.",
        Image: true,
      },
      {
        Label: "LHF rim condition",
        Rating: "Good",
        Comments: "Outter edge is slightly worn also.",
        Image: true,
      },
      {
        Label: "LHF tyre condition",
        Rating: "Fair",
        Comments: "",
        Image: true,
      },
      {
        Label: "LHR rim condition",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "LHR tyre condition",
        Rating: "Fair",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHR rim condition",
        Rating: "Fair",
        Comments: "",
        Image: true,
      },
    ],
  },
  {
    header: "BodyCondition",
    Value: 7,
    Description: [
      {
        Label: "Bonnet",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "LHF fender",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHF fender",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Front bumper bar",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "LHF door",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHF door",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "LHR door",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RHFR door",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "LH Side skirt",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "RH Side skirt",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "3/4 RH rear panel",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "3/4 LH rear panel",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Rear bumper bar",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Roof",
        Rating: "Good",
        Comments: "",
        Image: true,
      },
      {
        Label: "Paint depth discrepancies",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
    ],
  },
  {
    header: "TrackDriving",
    Value: 94,
    Description: [
      {
        Label: "Burnt rubber in wheels arches",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Tow hook cover (surround) condition",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Windscreen sand blasting",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Front bar sand blasting",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Bonnet sand blasting",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Worn front tyre shoulders",
        Rating: "MIN",
        Comments: "",
        Image: false,
      },
      {
        Label: "Worn rear tyres",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Hot spots on brake discs",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
    ],
  },
  {
    header: "RoadTest",
    Value: 83,
    Description: [
      {
        Label: "Brake shudder",
        Rating: "NO",
        Comments: "",
        Image: false,
      },
      {
        Label: "Cruise Control",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine coolant temperature",
        Rating: "Good",
        Comments: "",
        Image: false,
      },
      {
        Label: "4WD System",
        Rating: "NA",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine noise",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Turbocharger/wastegate noise",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Wheel bearings",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine performance",
        Rating: "Excellent",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine revving",
        Rating: "Excellent",
        Comments: "",
        Image: false,
      },
      {
        Label: "Exhaust smoke/Emissions",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Clutch Shudder",
        Rating: "NA",
        Comments: "",
        Image: false,
      },
      {
        Label: "Gearbox operation",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Differential noise",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Transmission clunking",
        Rating: "GOOD",
        Comments: "No clunking",
        Image: false,
      },
      {
        Label: "Steering/Suspension",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Clutch Engaging",
        Rating: "NA",
        Comments: "",
        Image: false,
      },
      {
        Label: "Brake operation",
        Rating: "Excellent",
        Comments: "",
        Image: false,
      },
      {
        Label: "Drivetrain slack",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Instrument cluster operation",
        Rating: "Excellent",
        Comments: "",
        Image: false,
      },
      {
        Label: "Ease of starting",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine oil temperature",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
      {
        Label: "Engine operating temperature",
        Rating: "GOOD",
        Comments: "",
        Image: false,
      },
    ],
  },
];
