import React, { createContext } from "react";
import { useState } from "react";

export const MyContext = createContext();

const Context = (props) => {
  const [count, setCount] = useState(0);
  const [index, setIndex] = useState(0);
  const [heightAct, setHeightAct] = useState(false);
  const [ownerPlanTabActive, setOwnerPlanTabActive] = useState(0);
  return (
    <MyContext.Provider
      value={{
        count,
        setCount,
        index,
        setIndex,
        heightAct,
        setHeightAct,
        ownerPlanTabActive,
        setOwnerPlanTabActive
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
export default Context;
