import React, { useEffect, useState } from 'react';
import './planDetails.css';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { InspectionCardsArray, planDetailsArray } from '../../utils/constants';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import { basicAuth } from '../../Api/crmPortal';
import CarInspection from '../../assets/images/payment/carInspection.png';
import OtpInput from 'react-otp-input';
import BuyNowComponent from '../../containers/buy-now/BuyNowComponent';
import FreeIcon from '../../assets/images/payment/freeIcon.png';
import BookInspectionModal from '../popups/BookInspectionModal';

const PlanDetailsNew = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const transactionStatus = queryParams.get('TransactionStatus');
  useEffect(() => {
    if (transactionStatus) setStepperVisible(true);
  }, [transactionStatus]);
  const [inspectionPlanDetails, setInspectionPlanDetails] = useState();
  const [selectedValue, setSelectedValue] = useState('Standard');
  const [planDetails, setPlanDetails] = useState();
  const [activePlanDetails, setActivePlanDetails] = useState();
  const [packageData, setPackageData] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [inspectionCount, setInspectionCount] = useState(1);
  const [stepperVisible, setStepperVisible] = useState(false);
  const [pageDisable, setPageDisable] = useState(false);
  const [mobileDesignView, setMobileDesignVisible] = useState(false);
  const [OTP, setOTP] = useState('');
  const handleInspectionCountChange = (eve) => {
    setInspectionCount(eve.target.value);
    if (eve.target.value === '1')
      setPackageData(activePlanDetails?.smallPackage);
    else
      setPackageData(
        activePlanDetails?.highPackage
          ? activePlanDetails?.highPackage
          : activePlanDetails?.smallPackage
      );
  };

  const planName = queryParams.get('InspectionType')
    ? queryParams.get('InspectionType')
    : 'Standard';

  const handleChange = (event) => {
    setSelectedValue(event);
    let selectedPlan = inspectionPlanDetails.filter((plan) =>
      plan?.packTitle.toLowerCase().includes(event.toLowerCase())
    );
    setActivePlanDetails(selectedPlan?.[0]);
    setPackageData(
      selectedPlan?.[0]?.smallPackage
        ? selectedPlan?.[0]?.smallPackage
        : selectedPlan?.[0]?.highPackage
    );
    setTotalAmount(
      selectedPlan?.[0]?.smallPackage
        ? selectedPlan?.[0]?.smallPackage?.totalPrice
        : selectedPlan?.[0]?.highPackage?.totalPrice
    );
  };

  const handleInspectionAmount = (event) => {
    // setStepperVisible(true);
    if (inspectionCount === 1) {
      setPackageData(
        event?.smallPackage ? event?.smallPackage : event?.highPackage
      );
      setTotalAmount(event?.totalPrice);
    } else {
      setPackageData(
        event?.highPackage ? event?.highPackage : event?.smallPackage
      );
    }
  };

  function parseInspectionFeatures(packages) {
    return packages?.map((item) => ({
      ...item,
      smallPackage: item?.smallPackage
        ? {
            ...item.smallPackage,
            inspectionFeatures: item?.smallPackage?.inspectionFeatures
              ? JSON.parse(item?.smallPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.smallPackage?.addonFeatures
              ? JSON.parse(item?.smallPackage?.addonFeatures)
              : null,
          }
        : null,
      highPackage: item?.highPackage
        ? {
            ...item.highPackage,
            inspectionFeatures: item?.highPackage?.inspectionFeatures
              ? JSON.parse(item?.highPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.highPackage?.addonFeatures
              ? JSON.parse(item?.highPackage?.addonFeatures)
              : null,
          }
        : null,
    }));
  }

  console.log('activePlanDetails', activePlanDetails, planDetails);
  const handlePageDisable = () => {
    // setPageDisable(true);
  };

  const handleInputChange = (e) => {
    setOTP(e);
  };

  const getInspectionPlans = async () => {
    const apiUrl = `${process.env.REACT_APP_CRM_URL}/inspectionpackagepairs/activepackagesfortenantid?tenantId=6`;
    try {
      const response = await axios.get(apiUrl, basicAuth);
      const inspectionData = parseInspectionFeatures(response?.data);
      // const inspectionData1 = planDetailsArray;
      const defaultPlan = planName
        ? inspectionData?.filter(
            (item) => item.packTitle?.toLowerCase() === planName?.toLowerCase()
          )
        : inspectionData?.[0];

      setInspectionPlanDetails(inspectionData);
      setPlanDetails(inspectionData);

      if (defaultPlan?.length > 0) {
        setActivePlanDetails(defaultPlan?.[0]);
        setPackageData(defaultPlan?.[0]?.smallPackage);
      } else {
        setActivePlanDetails(defaultPlan?.[0]);
        setPackageData(defaultPlan?.[0]?.smallPackage);
      }

      if (defaultPlan?.[0]?.smallPackage) {
        setPackageData(defaultPlan?.[0]?.smallPackage);
        setTotalAmount(defaultPlan?.[0]?.smallPackage?.totalPrice);
      } else if (defaultPlan?.[0]?.highPackage) {
        setPackageData(defaultPlan?.[0]?.highPackage);
        setTotalAmount(defaultPlan?.[0]?.highPackage?.totalPrice);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getInspectionPlans();
  }, []);

  return (
    <section className="planDetails">
      <div
        id="inspection-plans"
        className="container inspection-plan-container"
      >
        <div className="inspection-plan-container-left">
          <h3>
            <span style={{ color: '#003cc5', fontFamily: 'matterMedium' }}>
              200+ check points
            </span>
            <br />
            Inspection Protects your car & Guarantees Peace of Mind
          </h3>
          {/* <div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              row
              name="controlled-radio-buttons-group"
              value={inspectionCount}
              onChange={handleInspectionCountChange}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '15px',
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="1 Inspection"
                disabled={pageDisable}
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3 Inspections"
                disabled={pageDisable}
              />
            </RadioGroup>
          </div> */}
          <div className="inspection-plan-btn-container-mobile">
            <div className={'inspection-plan-card'} disabled={pageDisable}>
              <div className="inspection-plan-card-header">
                {planDetails?.map((inspect, index) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Radio
                      checked={selectedValue === inspect?.packTitle}
                      onChange={() => handleChange(inspect?.packTitle)}
                      value={inspect?.packTitle}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      disabled={pageDisable}
                    />
                    <h5 onClick={() => handleChange(inspect?.packTitle)}>
                      {inspect?.packTitle}
                    </h5>
                  </div>
                ))}
              </div>
              <div className="mobile-view-btn">
                {/* <div className="web-view-btn"> */}
                <h6 style={{ color: '#003cc5' }}>
                  Rs {activePlanDetails?.smallPackage?.totalPrice}
                </h6>
                <Button
                  sx={{
                    width: '60%',
                    height: '40px',
                    fontSize: '18px',
                    backgroundColor: '#003cc4',
                    borderRadius: 1,
                    textTransform: 'none',
                    fontFamily: 'matterMedium',
                    padding: '5px 5px',
                  }}
                  variant="contained"
                  disabled={pageDisable}
                  onClick={() => setMobileDesignVisible(true)}
                >
                  Book Inspection
                </Button>
              </div>
            </div>
          </div>
          <div className="inspection-plan-btn-container ">
            {planDetails?.map((inspect, index) => (
              <div
                key={index}
                className={
                  inspect?.packTitle === activePlanDetails?.packTitle
                    ? 'inspection-plan-card-active '
                    : 'inspection-plan-card'
                }
                disabled={pageDisable}
                onClick={() => handleInspectionAmount(activePlanDetails)}
              >
                <div
                  className="inspection-plan-card-header"
                  onClick={() => handleChange(inspect?.packTitle)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Radio
                      checked={selectedValue === inspect?.packTitle}
                      onChange={() => handleChange(inspect?.packTitle)}
                      value={inspect?.packTitle}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      disabled={pageDisable}
                    />
                    <h5>{inspect?.packTitle}</h5>
                  </div>
                  <h5>
                    Rs{' '}
                    {inspectionCount == 1
                      ? inspect?.smallPackage?.totalPrice
                      : inspect?.highPackage?.totalPrice
                      ? inspect?.highPackage?.totalPrice
                      : inspect?.smallPackage?.totalPrice}
                  </h5>
                </div>
                <div className="web-view-btn">
                  {selectedValue === inspect?.packTitle && (
                    <Button
                      sx={{
                        width: '80%',
                        height: '40px',
                        fontSize: '20px',
                        backgroundColor: '#003cc4',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontFamily: 'matterMedium',
                      }}
                      variant="contained"
                      disabled={pageDisable}
                      onClick={() => setStepperVisible(true)}
                    >
                      Book Inspection
                    </Button>
                  )}
                </div>
                <div className="mobile-view-btn">
                  {selectedValue === inspect?.packTitle && (
                    <Button
                      sx={{
                        width: '80%',
                        height: '40px',
                        fontSize: '20px',
                        backgroundColor: '#003cc4',
                        borderRadius: 1,
                        textTransform: 'none',
                        fontFamily: 'matterMedium',
                      }}
                      variant="contained"
                      disabled={pageDisable}
                      onClick={() => setMobileDesignVisible(true)}
                    >
                      Book Inspection
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="inspection-plan-btn-container">
            <Button
              variant="outlined"
              fullWidth
              sx={{
                fontFamily: 'matter',
                border: '1px solid black',
                color: 'black',
                height: '50px',
              }}
              endIcon={<FileDownloadOutlinedIcon />}
            >
              <RouterLink
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontSize: '18px',
                }}
                target="_blank"
                to={'/sample-report'}
              >
                Download Sample Report
              </RouterLink>
            </Button>
          </div>
        </div>
        {stepperVisible ? (
          <div className="inspection-plan-stepper-container">
            {/* <ArrowCircleLeftIcon /> */}
            <div className="inspection-plan-stepper-body">
              <BuyNowComponent
                selectedValue={selectedValue}
                packageData={packageData}
                inspectionCount={inspectionCount}
                setStepperVisible={() => setStepperVisible(false)}
                totalAmount={totalAmount}
                setPageDisable={handlePageDisable}
              />
            </div>
          </div>
        ) : (
          <div className="inspection-plan-card-container">
            {InspectionCardsArray?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={'inspection-plan-card-' + Number(index + 1)}
                >
                  <div className="inspection-plan-card-content">
                    <div className="inspection-plan-card-header-container">
                      <div className="inspection-plan-card-header-left">
                        <p>{item?.header}</p>
                      </div>
                      <div className="inspection-plan-card-header-right">
                        {index == 3 ? (
                          <img src={FreeIcon} width="50px"></img>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <div className="inspection-plan-card-check-points">
                              <h5> {item?.checkPoints}</h5>
                            </div>
                            <p
                              style={{
                                fontSize: '12px',
                                paddingTop: '0px',
                                fontFamily: 'matterThin',
                              }}
                            >
                              Check Points
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <Divider
                      style={{ borderColor: 'black', marginTop: '10px' }}
                    />
                    <div className="inspection-plan-card-list-items">
                      {item?.inspectionItems.map((feature, subIndex) => (
                        <Typography key={subIndex + 1}>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'nowrap',
                              fontFamily: 'matterMedium',
                              flexDirection: 'row',
                              gap: '5px',
                            }}
                          >
                            <CheckCircleIcon
                              className={'circle-tick-' + Number(index + 1)}
                            />
                            {feature}
                          </span>
                        </Typography>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
            <BookInspectionModal
              selectedValue={selectedValue}
              packageData={packageData}
              open={mobileDesignView}
              handleClose={() => setMobileDesignVisible(false)}
              content="test"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default PlanDetailsNew;
