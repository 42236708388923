import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import Privacy from './privacy'

function index() {
  return (
    <div>
      <Header />
      <Privacy />
      <Footer />
    </div>
  )
}

export default index
